import LocationIcon from '../../src/img/locationIcon.png';
import GlobeIcon from '../../src/img/GlobeIcon.png';
import PhoneIcon from '../../src/img/phoneIcons.png';
import LocationIcon2 from '../../src/img/locationIcon2.png';
import GlobeIcon2 from '../../src/img/GlobeIcon2.png';
import PhoneIcon2 from '../../src/img/phoneIcons2.png';
const currentDate = new Date().toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
});
export const InvoiceConstants = {
    HeaderIcons: {
        phone: {
            src: PhoneIcon,
            text: '+92 (0331) 27 29 444\n+92 (051) 27 29 444'
        },
        location: {
            src: LocationIcon,
            text: 'Plaza #100, G-1, Walayat Complex,\nPhase 7 Bahria Town, Rawalpindi.'
        },
        globe: {
            src: GlobeIcon,
            text: 'info@vectorsnscalars.com\nwww.vectorsnscalars.com'
        }
    },
    HeaderIcons2: {
        phone: {
            src: PhoneIcon2,
            text: '+92331-3055001\n+92 345 5329935'
        },
        location: {
            src: LocationIcon2,
            text: 'Plot No:12 Nursery Area Sect,\n H/9-3 Islamabad.'
        },
        globe: {
            src: GlobeIcon2,
            text: 'www.easeloutdoor.com\nwww.easeloutdoor.com/our-services'
        }
    },
    BelowHeader: {
        'Reference': 'Uzma Hafeez',
        'GST': '3277876170941',
        'Date': currentDate,
        'NT': '7902748-1',
    },

    BelowHeader2: {
        'Reference': '06746',
        'GST': '',
        'Date': currentDate,
        'NT': '',
    },
    firstOfAll: `First of all, on behalf of “ Vectors n Scalars” ,we are highly thankful to you for providing us this opportunity.`,
    Note: `a) Our current Delivery Period for complete supply is 15 working days from the latest date of receipt
and acceptance of the following. b) Site Procurement shall be executed upon receiving 80% Advance. c) Any
change shall be noted and initiated by both parties. d) Fabric is in under international warranty for 5 years for
any manufacturing Fault. Exclusion: Any Damage or fault due to improper use, sharp objects, Bullets etc. e)
Structure is under warranty for 1 year for any fabrication fault. f) All prices are exclusive of taxes.
g) Quotation validity for 5 days`,
    Note2: `a) 80%adv required upon confirmation of order .
b) Fabric under international warrenty for 10 year,s. {for color & water repalent}
c) For complete Execution will take 7 working Days only
d) Company is not responsible for any sharp cut or fire hazerd to fabric.
e) Rest payment will be made on completion of job.
f) Above offered are exclusive of any govt Taxex.
`
};
export const formatText = (text) => {
    // Replace any unintended newlines within items with a space
    text = text.replace(/\n/g, ' ');

    // Define a regex to split on each bullet or number followed by a closing parenthesis
    const items = text.split(/(?=[a-zA-Z]\))/).map(item => item.trim()).filter(item => item);

    // Format each item with a newline between them
    const formattedText = items.join('\n\n');

    return formattedText;
}
