import React from 'react';
import './contactus.css'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, TextField, Button, Grid } from '@material-ui/core';
import { Instagram, Facebook, Twitter, Email } from '@material-ui/icons';
import { useMediaQuery, useTheme } from '@material-ui/core/';
import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
const ContactUsPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const handlesubmit = () => {
    alert(`Hello! We're currently hard at work on our Contact Us page to provide you with the best experience. Stay tuned for updates, and in the meantime, feel free to reach out to us through our existing contact channels. Thank you for your patience!`)
  }
  return (
    <>
      <Header />
      <div class="main-container">
        <div class="overlay-content" style={{ marginTop: isTablet ? "25%" : "5%" }}>
          <Grid container >
            <Grid item xs={12} md={6} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div class="divleft">
                <div class="content-box">
                  <Typography variant='h4'>Get in touch</Typography>
                  <p style={{ textAlign: "justify" }}>
                    Want to get in touch, We'd love to hear from you. Here's how you can reach us .....
                  </p>
                  <div style={{ textAlign: "center" }}>
                    <a class="share-icon">
                      <Instagram style={{ marginLeft: "5%", marginRight: "5%" }} />
                    </a>
                    <a class="share-icon">
                      <Facebook style={{ marginLeft: "5%", marginRight: "5%" }} />
                    </a>
                    <a class="share-icon">
                      <Twitter style={{ marginLeft: "5%", marginRight: "5%" }} />
                    </a>
                    <a class="share-icon">
                      <Email style={{ marginLeft: "5%", marginRight: "5%" }} />
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className='divright'>
                <form class="form-box">
                  <div class="container-block form-wrapper">
                    <Typography variant='h6'>
                      <a class="link" href="">
                      </a>
                      Get a quote
                    </Typography>
                    <p class="text-blk contactus-subhead">
                      We will get back to you in 24 hours
                    </p>
                    <div class="responsive-container-block">
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        {/* <TextField class="input" id="ijowk-7" name="FirstName" placeholder="First Name"></TextField>
                    <TextField class="input" id="indfi-5" name="Last Name" placeholder="Last Name"></TextField>
                    <TextField class="input" id="ipmgh-7" name="Email" placeholder="Email"></TextField>
                    <TextField class="input" id="imgis-6" name="PhoneNumber" placeholder="Phone Number"></TextField> */}
                        <CssTextField className={classes.fields}
                          style={{ color: "white" }}
                          id="outlined-basic"
                          label="FirstName"
                          variant="outlined"
                          className={classes.titleInput}
                          InputProps={{
                            className: classes.inputText
                          }}
                        />
                        <CssTextField className={classes.fields}
                          style={{ color: "white" }}
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          className={classes.titleInput}
                          InputProps={{
                            className: classes.inputText
                          }}
                        />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <CssTextField className={classes.fields}
                            style={{ color: "white" }}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className={classes.titleInput2}
                            InputProps={{
                              className: classes.inputText
                            }}
                          />
                          <CssTextField className={classes.fields}
                            style={{ color: "white" }}
                            id="outlined-basic"
                            label="PhoneNumber"
                            variant="outlined"
                            style={{ width: "100%", marginTop: "2%" }}
                            InputProps={{
                              className: classes.inputText
                            }}
                          />
                        </div>
                        <div id="i634i-7">
                          <CssTextField
                            rows='5'
                            multiline
                            id="outlined-basic"
                            label="Description"
                            variant="outlined"
                            className={classes.titleInput}
                            InputProps={{
                              className: classes.inputText
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "4%" }}>
                      <Button style={{ background: "#c4b205", color: "white", width: "100%" }} onClick={handlesubmit}>Submit</Button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer backgroundColor='#1f1f1f' />

    </>
  );
};


const useStyles = makeStyles((theme) => ({
  titleInput: {
    width: '100%',
    marginTop: '2%'
  },
  titleInput2: {
    width: '100%',
    marginTop: '2%',
    marginRight: '2%'
  },
  inputText: {
    color: 'white',
  }

}));
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#c4b205',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#c4b205',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c4b205',
      },
      '& input': {
        color: 'white', // Set the text color to white
      },
    },
  },
})(TextField);

export default ContactUsPage;
