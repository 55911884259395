import React from "react";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function AnmatedNumbers(props) {
  const isMobile = useMediaQuery('(max-width:601px)');
  return (
    <div>
      <AnimatedNumbers
        includeComma
        animateToNumber={props.years}
        fontStyle={{ fontSize: 80,color:"white" ,fontFamily:isMobile?"arial":"cursive",fontWeight:"bold"}}
        locale="en-US"
        configs={[
          { mass: 1, tension: 220, friction: 100 },
          { mass: 1, tension: 180, friction: 130 },
          { mass: 1, tension: 280, friction: 90 },
          { mass: 1, tension: 180, friction: 135 },
          { mass: 1, tension: 260, friction: 100 },
          { mass: 1, tension: 210, friction: 180 },
        ]}
      ></AnimatedNumbers>

    </div>
  );
}

export default AnmatedNumbers;