import authReducer from "./authentication";
import productReducer from "./productReducer";
import authadminReducer from "./authenticateadminreducer";
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { combineReducers } from "redux";
import { ProjectDataReducer, OtherInfoReducer } from "./ProjectDataReducer"
import { HomeDataReducer, imagesReducer, scrollsReducer, SubscrollsReducer } from "./homedatareducer"; // Make sure to import imagesReducer
import ExploreProductScreenReducer from './ExploreProductScreenReducer'
const rootReducer = combineReducers({
    authadmin: authadminReducer,
    auth: authReducer,
    product: productReducer,
    firebase: firebaseReducer,
    products: HomeDataReducer,
    images: imagesReducer,
    scrolls: scrollsReducer,
    exploreproductsData: ExploreProductScreenReducer,
    subscrolldata: SubscrollsReducer,
    ProjectsData: ProjectDataReducer,
    OtherInfo: OtherInfoReducer,
    firestore: firestoreReducer
});

export default rootReducer;
