import React, { useState, useEffect } from 'react';
import { Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core/';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getFirestore, collection, getDocs, getDoc, updateDoc, doc } from 'firebase/firestore';
import app from '../../../config/fbConfig';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import image from '../../../img/use_your_illusion.png'

const SimpleModal = (Props) => {
  const classes = useStyles();
  const [loading, setshowloading] = useState(true);
  const [loading2, setshowloading2] = useState(true);
  const [products, setproducts] = useState([]);
  const [crouselData, setcrouselData] = useState([]);
  const db = getFirestore(app);
  const [searchQuery, setSearchQuery] = useState('');
  const [titles,settitles]=useState()
  const [desc,setdesc]=useState()
  const [showloader2,setshowloader2]=useState(false)
  const [type,setType]=useState()
  const [bigloading,setsbigloading]=useState(false)

  // useEffect(() => {
  //   console.log('runned 1')
  //   const fetchData = async () => {
  //     const collectionRef = collection(db, 'products');
  //     const productsArray = [];

  //     try {
  //       const querySnapshot = await getDocs(collectionRef);
  //       const products = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  //       setproducts(products);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   fetchData();
  // }, []);
 
  useEffect(() => {
    console.log('runned 2')
    const fetchCrouselData = async () => {
      setshowloading(true);
      const collectionRef = collection(db, 'MainScreenEditables');
      try {
        const updatedDocSnapshot = await getDoc(doc(collectionRef, Props.value));
        if (updatedDocSnapshot.exists()) {
          const updatedDocData = updatedDocSnapshot.data();
          setcrouselData(updatedDocData);
        } else {
          console.log('Document does not exist');
        }
        setshowloading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCrouselData();
  }, []);
  useEffect(() => {
    const fetchProductsData = async () => {
      try {
        setsbigloading(false);
        const db = getFirestore(app);
        const collectionRef = collection(db, 'products');
    
        const snapshot = await getDocs(collectionRef);
        const products = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setproducts(products);
        setsbigloading(true)
      } catch (error) {
        console.log('Error getting documents: ', error);
      }
    };
    
    fetchProductsData(); // Call the function to fetch and update the products data
  
}, []);

  const handleClose = () => {
    Props.handleClose();
  };

  const handleSearch = (event) => {
    // Handle the search operation based on searchQuery
    setSearchQuery(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const [selectedProduct, setSelectedProduct] = useState([]);

  // Function to handle when a product is clicked
  const handleProductClick = (title) => {
    setshowloading2(true)
    const productWithTargetTitle = products.find((product) => product.title === title);
    setSelectedProduct(productWithTargetTitle);
    setType(productWithTargetTitle.type)
  };
  const handletitle=(e)=>{
    settitles(e.target.value)
  }
  
  const handleDesc=(e)=>{
    setdesc(e.target.value)
  }
  const handleSubmit= async ()=>{
    setshowloader2(true)
    try {
    const collectionRef = collection(db, 'MainScreenEditables');
    await updateDoc(doc(collectionRef, Props.value), { image: selectedProduct.image ,title:titles,description:desc,type:type});
        
    setshowloader2(false)
    Props.handleClose()
}
    catch(error){
      console.error('Error updating documents:', error);
        
    setshowloader2(false)
    }
  }
  return (
    <div>
      <Modal open={true} onClose={handleClose}>
        <div
          style={{
            position: 'relative',
            top: '50%',
            left: '50%',
            height: '90vh',
            maxHeight: '90vh',
            overflowY: 'auto',
            width: '90vw',
            transform: 'translate(-50%, -50%)',
            backgroundImage:`url(${image})`,
            padding: '20px',
            borderRadius: '5px',
            outline: 'none',
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            flexDirection: 'column',
           
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', background: 'grey' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold',color:"white", textTransform: 'capitalize', zIndex: '1' }}>
              Edit {Props.value}
            </Typography>
            <IconButton onClick={handleClose} style={{ padding: '0', marginLeft: 'auto' }}>
              <CloseIcon />
            </IconButton>
          </div>
          {loading && (
            <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress style={{ color: 'yellow' }} />
            </div>
          )}
          <div className={classes.body}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ flexDirection: 'column' }}>
                <span style={{ textAlign: 'center',color:"white" }}>
                  <Typography variant="h5">{crouselData.title}</Typography>
                </span>
                <div style={{ textAlign: 'center' }}>
                  <img src={crouselData.image} alt="." className={classes.image} />
                </div>
                <div style={{ padding: '5%', textAlign: 'justify',color:"white" }}>
                  <Typography variant="h6">{crouselData.description}</Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
          <Divider style={{margin:"5%",background:"white"}}/>
            <div style={{ textAlign: 'center' ,color:"white"}}>
              <Typography variant="h5">Changes Form</Typography>
            </div>
            {bigloading ? (<div className={classes.formContainer}>
              <TextField
                className={classes.textField}
                label="Search"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton>
                        <SearchIcon style={{color:"white"}}/>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Display the filtered products */}
              <div style={{ marginTop: '20px', textAlign: 'center', height: '100px', overflowY: 'auto' }}>
          {filteredProducts.map((product) => (
            <Button key={product.id} style={{color:"white",margin:"1%"}} onClick={() => handleProductClick(product.title)}>
              {product.title}
            </Button>
          ))}
        </div>
         
            {loading2 && <div style={{display:"flex",flexDirection:"column",borderTop:"1px solid white",marginTop:"2%"}}>
                <Typography variant="h6" style={{display:"flex",justifyContent:"center",color:"white"}}>Founded product  </Typography>
                <div style={{display:"flex",justifyContent:"center"}}><ArrowDownward style={{color:"white"}}/></div>
                <img className={classes.image} src={selectedProduct.image}alt='NOT SELECTED YET'style={{color:"white"}}/>
            </div>}
            <div className={classes.container}>
              <div style={{textAlign:"center"}}>
                <TextField 
                fullWidth
                value={titles}
                style={{margin:"2%",border:"1px solid #c4b205",width:"40%"}}
                defaultValue="Enter title"
                onChange={handletitle}
                id="outlined-basic"
                 label="Outlined"
                  variant="outlined" />
              </div>
                 <TextField 
                  rows='5'
                  multiline
                 fullWidth
                 style={{margin:"2%",border:"1px solid #c4b205"}}
                 value={desc}
                 id="outlined-basic"
                  label="Outlined"
                  defaultValue="Enter discription"
                   variant="outlined" 
                   onChange={handleDesc}/>
                </div>
                {showloader2 && <div style={{textAlign:"center",marginTop:"3%"}}><CircularProgress style={{color:"grey"}}/></div>}

              <Button
                variant="contained"
                style={{ background: '#c4b205', color: 'white',marginTop:"2%",marginBottom:"5%" }}
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
              </div>)
              :
               (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                  <CircularProgress style={{color:"yellow"}} />
                </div>
              )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    background: 'white',
    height: '70vh',
    width: '70vw',
  },
  body: {
    marginTop: '5%',
  },
  image: {
    height: '25vh',
    width: 'auto',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    border:"1px solid white",
    borderRadius:"2%",
    padding:"2%"

  },
  textField: {
    marginBottom:0,
    border:"1px solid #c4b205"
  },
  container:{
    width:"70%",
    display:"flex",
    flexDirection:"column"
  }
  
}));

export default SimpleModal;
