import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core';
import Project from "./Project/Project";
import content from "../../../constants/content";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import AnimationProject from '../../../lottie/project';
import Header from "../../Header";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Footer from "../Footer";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ScrollingHor from "./hrscroll";
import './project.css'
import ShowProjectsGrid from "./ShowProjectsGrid/projectShowGrid";
import InteractiveBackground from "./AnimatedBackground/animatedBackground";
import 'firebase/storage';
import { SetAllProjects } from "../../../store/actions/ProjectData";
import { SetOtherInfo } from "../../../store/actions/ProjectData";
import { setdataFetched } from "../../../store/actions/ProjectData";
import {
  getFirestore,
  collection,
  getDocs,
  query as firestoreQuery,
  getDoc,
  doc,
  where,
} from 'firebase/firestore';
import app from '../../../config/fbConfig';
import {
  Container,
} from '@material-ui/core';
import ProjectLoader from "../../../lottie/loader_Project";

const Projects = () => {
  const classes = useStyles();
  const db = getFirestore(app);
  const isMobile = useMediaQuery('(max-width:601px)');
  const [loading, setloading] = useState(false)
  const projects = useSelector(state => state.ProjectsData || []);
  const projectdata = useSelector(state => state.OtherInfo || []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!projects.length) {
      const fetchData = async () => {
        setloading(true);
        try {
          const db = getFirestore(app);
          const collectionRef = collection(db, 'products');
          const filteredProductQuery = firestoreQuery(collectionRef, where('type', '==', 'Project'));
          const snapshot = await getDocs(filteredProductQuery);
          const products = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          dispatch(SetAllProjects(products));
        } catch (error) {
          console.log('Error getting documents: ', error);
        }
        try {
          const collectionRef = collection(db, 'MainScreenEditables');
          const docSnap = await getDoc(doc(collectionRef, 'ProjectNumbers'));

          if (docSnap.exists()) {
            const data = docSnap.data();
            dispatch(SetOtherInfo(data));
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
        setloading(false);
      };
      fetchData();
    }
  }, [dispatch]);



  function findNearestDateObjects(objectsArray, count = 7) {
    if (!Array.isArray(objectsArray) || objectsArray.length === 0) {
      return []; // Return an empty array for empty arrays or non-array inputs
    }

    const currentDate = Date.now();

    // Create an array to store the objects with their time differences
    const nearestObjects = [];

    // Loop through the objects and calculate their time differences
    for (const obj of objectsArray) {
      const timeDifference = Math.abs(obj.date - currentDate);
      nearestObjects.push({ object: obj, timeDifference });
    }

    // Sort the nearestObjects array by timeDifference in ascending order
    nearestObjects.sort((a, b) => a.timeDifference - b.timeDifference);

    // Get the top `count` objects or all available objects if count exceeds array length
    const topObjects = nearestObjects.slice(0, Math.min(count, nearestObjects.length)).map(item => item.object);

    return topObjects;
  }


  const nearestObject = findNearestDateObjects(projects);
  return (
    <div>
      <>
        <Header />
        <div container className={isMobile ? classes.container : classes.container1}>
          <AnimationProject />
        </div>
        <div className={classes.titlecontainer}>
          <div class="nine">
            <h1>Our Projects<span>Furnish Fusion</span></h1>
          </div>
        </div>
        <Container className={classes.root}>
          <Typography variant="body1" gutterBottom className={classes.headingcolor}>
            Explore our showcase of meticulously designed office spaces transformed using our exceptional furniture collection.
            Witness creativity and functionality seamlessly merge in these remarkable projects.
          </Typography>
        </Container>
        <ScrollingHor data={nearestObject} title={'Recents'} subheader={'Check Latest Happenings'} />
        <ShowProjectsGrid data={projects} />
        <InteractiveBackground data={projectdata} />
        <div style={{ marginTop: "10%" }}>
          <Footer />
        </div>
      </>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },

  container1: {
    marginTop: "8%"
  },
  container: {
    marginTop: "20%"
  },
  titlecontainer: {
    textAlign: "center",
    marginTop: "20px"
  },
  title: {
    color: 'white',
  },
  card: {
    background: "white",
    maxWidth: 300,
    margin: theme.spacing(2),
  },
  media: {
    height: 200,
  },
}));

export default Projects;
