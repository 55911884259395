import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import menuItems from '../../../constants/menuItems'
import {  getFirestore,collection, updateDoc, doc } from 'firebase/firestore';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import app from '../../../config/fbConfig';
import './customselect.css'
import { CircularProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    color:"white"
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function CustomSelect() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([...menuItems.products.outdoorProducts, ...menuItems.products.indoorProducts,...menuItems.products.MoreOutdoor]);
  const [right, setRight] = React.useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const [loader,setshowloader]=useState(false)
  const [showsucess,setshowsucess] = useState(false)
    const db = getFirestore(app);
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    if (right.length + leftChecked.length <= 3) { // Check if right list won't exceed three items
      setRight(right.concat(leftChecked));
      setLeft(not(left, leftChecked));
      setChecked(not(checked, leftChecked));
    }
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };
const handleUpload=async ()=>{
    setshowloader(true)
    try {
        const collectionRef = collection(db, 'MainScreenEditables');
        await updateDoc(doc(collectionRef, 'ThreeScrolls'), { scroll1name: right[0] ,scroll2name:right[1],scroll3name:right[2]});  
        setshowloader(false)
        setshowsucess(true)
        setTimeout(() => {
            setshowsucess(false);
          }, 4000); // 3000 milliseconds = 3 seconds
    }
        catch(error){
          console.error('Error updating documents:', error);
        setshowloader(false)
        }
}
  const customList = (title, items) => (
    <Card style={{ border: "2px solid white" }}>
    <CardHeader
      className={classes.cardHeader}
      style={{ color: "white" }}
      avatar={
        <Checkbox
          style={{ color: "white" }}
          onClick={handleToggleAll(items)}
          checked={numberOfChecked(items) === items.length && items.length !== 0}
          indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
          disabled={items.length === 0}
          inputProps={{ 'aria-label': 'all items selected' }}
        />
      }
      title={title}
      subheader={`${numberOfChecked(items)}/${items.length} selected`}
      subheaderTypographyProps={{ style: { color: "white" } }}
    />
    <Divider />
    <List className={classes.list} dense component="div" role="list">
      {items.map((value) => {
        const labelId = `transfer-list-all-item-${value}-label`;

        return (
          <ListItem key={value} button onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                style={{ color: "white" }}
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText style={{ color: "white" }} id={labelId} primary={`${value}`} />
          </ListItem>
        );
      })}
    </List>
    {title==='Select any three'&&<div style={{textAlign:"center"}}>
    {/* <Button style={{color:"white",background:"#c4b205",margin:"2%"}} onClick={handleUpload}>Save Changes</Button> */}
    <Typography style={{marginBottom:"2%"}}>
        {loader && <div style={{textAlign:"center"}}><CircularProgress style={{color:'#c4b205'}}/></div>}
        <Link href="#" style={{color:"#c4b205"}} onClick={handleUpload}>
            Save Changes
        </Link>
        {showsucess && <div style={{textAlign:"center"}}> Sucessfully updated documents</div>}
    </Typography>
    </div>}
  </Card>
  )
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList('Choices', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" >
          <Button
          style={{color:"white",border:"2px solid white"}}
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
          style={{color:"white",border:"2px solid white"}}
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Select any three', right)}</Grid>
    </Grid>
  );
}
