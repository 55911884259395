import React from "react";
import {Box, Grid, Typography, IconButton} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useHistory } from 'react-router-dom';
import {useMediaQuery,useTheme} from '@material-ui/core/';


const Project = props => {
    const history = useHistory();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const ismobile = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles()
    const handleProjectClick=(index)=>{
        window.scrollTo(0, 0);
        history.push({
          pathname: `/project/${index.title.replace(/\s/g, '-').toLowerCase()}`,
          state: { data: index }
        });  
        window.scrollTo(0, 0);
      }
    return(
        <Grid container style={{borderBottom:"1px solid grey",
        borderRight:isTablet && !ismobile?"0px solid grey":"1px solid grey",
        borderBottomRightRadius:"2%",
        padding:"1%",
        
        paddingRight:isTablet && !ismobile?"25%":'1%',
        paddingLeft:isTablet && !ismobile?"25%":'1%'}}>
            <Grid item xs={3}>
                <img src={props.image} alt='' className={classes.image}/>
            </Grid>
            <Grid item xs={9}> 
                <Typography variant='h6' className={classes.title}>
                    {props.title}
                </Typography>
                    <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"Center"}}>
                    <Box className={classes.desc}>
                        <Typography variant='body1' className={classes.category}>
                            {props.location}
                        </Typography>
                    </Box>
                    <IconButton className={classes.icon} onClick={() => handleProjectClick(props.data)}>
                            <OpenInNewIcon style={{fontSize: 15}}/>
                        </IconButton>
                    </div>
                    <div className={classes.desc} >{props.category}</div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    image: {
        width: 80,
        height: 80
        
    },
    desc: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 1,  // Restrict to one line
        color: 'white',
        marginLeft: 10,
      },
    icon:{
        color: 'white',
        backgroundColor: '#363636',
        marginLeft: 10,
    },
    category:{
        color: theme.palette.secondaryText
    },
    title: {
        color: 'white',
    }
}))

export default Project;
