import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { useTransition, animated } from 'react-spring';
import { makeStyles } from '@material-ui/core/styles';
import Instagram from '@material-ui/icons/Instagram';
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import menuItems from '../../constants/menuItems';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const SocialMediaBar = () => {
  const classes = useStyles();

  const instagram = <Instagram />;
  const facebook = <Facebook />;
  const twitter = <Twitter />;

  const issmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [items] = useState([
    { element: instagram, key: 1 },
    { element: facebook, key: 2 },
    { element: twitter, key: 3 },
  ]);

  const transitions = useTransition(items, {
    from: { transform: 'translate3d(0,40px,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-40px,0)' },
  });

  const handleButtonClick = (socialMedia) => {
    if (socialMedia === 2) {
        // Replace this URL with the actual Facebook URL
        const facebookURL = menuItems.FbUrl
        window.open(facebookURL, '_blank');
    }
    if (socialMedia === 1) {
      // Replace this URL with the actual Facebook URL
      const url = menuItems.instaurl
      window.open(url, '_blank');
  }
};

  return (
    <Box className={classes.socialMedia}>
      {transitions((style, item) => (
        <animated.div key={item.key} style={style}>
          <IconButton
            className={classes.socialMediaIcon}
            onClick={() => handleButtonClick(item.key)}
          >
            {item.element}
          </IconButton>
        </animated.div>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  socialMedia: {
    display: 'flex',
    flexDirection: 'column',
  },
  socialMediaIcon: {
    color: 'white',
    fontSize: 30,
  },
}));

export default SocialMediaBar;
