import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import HomePageContent from "../../constants/HomeContent";

import {useMediaQuery,useTheme} from '@material-ui/core/';
const OutdoorFurnitureDescription = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid item xs={12} md={6} lg={6}>
      <div style={{ padding: isTablet?"0px":"20px",textAlign:isTablet?"left":"justify" }}>
        <Typography variant="h5" gutterBottom  style={{color:"white",fontFamily: 'Tahoma  ',display:"flex",justifyContent:"center",fontWeight:"900"}}>
        {HomePageContent.Outdoortitle}
        </Typography>
        <Typography variant="body1" paragraph style={{wordWrap:"break-word",color: '#C4C1C1',fontWeight:"lighter",fontSize:15}}>
            {HomePageContent.Outdoordescription}
        </Typography>
      </div>
    </Grid>
  );
};

const IndoorFurnitureDescription = () => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid item xs={12} md={6} lg={6}>
      <div style={{ padding: isTablet?"0px":"20px" ,textAlign:isTablet?"left":"justify"}}>
        <Typography variant="h5" gutterBottom style={{color:"white",fontFamily: 'Tahoma  ',fontWeight:"900",display:"flex",justifyContent:"center",}} >
        {HomePageContent.Indoortitle}
        </Typography>
        <Typography variant="body1" paragraph={true} style={{wordWrap:"break-word",color: '#C4C1C1',fontWeight:"lighter",fontSize:15}}>
        {HomePageContent.Indoordescription}
        </Typography>
      </div> 
    </Grid>
  );
};

export default function FurnitureDescriptions() {
  return (
    <Grid container style={{margin:"5%",marginBottom:"0"}}>
      <OutdoorFurnitureDescription />
      <IndoorFurnitureDescription />
    </Grid>
  );
}
