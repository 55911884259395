import React, { useEffect, useState } from "react";
import PaginationElement from "../../components/PaginationElement/PaginationElement";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './slick-theme.css'
import SideBar from "../../components/SideBar/SideBar";
import { useMediaQuery } from '@material-ui/core/';
import ScrollingHorizontally from './HrScroll.js';
import Footer from "../../components/Footer/Footer";
import HrScrollOptional from "./optionalScrolls";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AnimationLoader from "../../lottie/loader_home";
import { useDispatch } from 'react-redux';
import { setSevenProducts, setImagesData, setScrollsData } from "../../store/actions/homedata"; // Update the import path
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "../../components/Header";
import CustomDivider from "../../components/CustomDivider/customDivider.js";
import { Button, Modal, TextField, Box, List, ListItem, ListItemText } from '@material-ui/core';
import SearchButtonModal from "../../components/modals/searchModal.js";
import SearchIcon from '@material-ui/icons/SearchOutlined';
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  query as firestoreQuery, where, query,
  orderBy, doc,
  limit,
} from 'firebase/firestore';
import app from '../../config/fbConfig';
import FurnitureDescriptions from "./aboutsection";
const settings = {
  arrows: false,
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  verticalSwiping: false,
  dotsClass: "vertical-dots",

};

const HomeScreen = () => {
  const sevenProducts = useSelector(state => state.products);
  const images = useSelector(state => state.images);
  const scrolls = useSelector(state => state.scrolls);
  const history = useHistory()
  const dispatch = useDispatch();
  const [header, setHeader] = useState("header")
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header")
    } else if (window.scrollY > 70) {
      return setHeader("header2")
    }
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  const openWhatsApp = () => {
    const phoneNumber = '923312729444';
    const messageUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(messageUrl, '_blank');
  };
  useEffect(() => {
    if (!sevenProducts.length) {
      const fetchProductsData = async () => {
        try {
          const db = getFirestore(app);
          const collectionRef = collection(db, 'products');

          const orderedProductQuery = query(collectionRef, orderBy('date', 'desc')); // Order by date in ascending order

          const limitedProductQuery = query(orderedProductQuery, limit(7));

          const snapshot = await getDocs(limitedProductQuery);
          const products = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          dispatch(setSevenProducts(products));
        } catch (error) {
          console.log('Error getting documents: ', error);
        }
      };

      fetchProductsData(); // Call the function to fetch and update the products data
    }
  }, [dispatch]);
  useEffect(() => {
    if (!images.length) {
      const fetchImages = async () => {
        try {
          const db = getFirestore(app);
          const collectionRef = collection(db, 'MainScreenEditables');

          // Create an array of promises for each document fetch
          const fetchPromises = [
            getDoc(doc(collectionRef, 'Crousel1')),
            getDoc(doc(collectionRef, 'Crousel2')),
            getDoc(doc(collectionRef, 'Crousel3')),
          ];

          // Await all promises to resolve concurrently
          const [doc1, doc2, doc3] = await Promise.all(fetchPromises);

          const imagesData = [
            doc1.exists()
              ? {
                image: doc1.data().image,
                title: doc1.data().title,
                description: doc1.data().description,
                type: doc1.data().type,
              }
              : null,
            doc2.exists()
              ? {
                image: doc2.data().image,
                title: doc2.data().title,
                description: doc2.data().description,
                type: doc2.data().type,
              }
              : null,
            doc3.exists()
              ? {
                image: doc3.data().image,
                title: doc3.data().title,
                description: doc3.data().description,
                type: doc3.data().type,
              }
              : null,
          ].filter((item) => item !== null);

          dispatch(setImagesData(imagesData));
        } catch (error) {
          console.error('Error fetching images:', error);
        }
      };

      fetchImages();
    }
  }, [dispatch, images.length]); // Added images.length to dependencies

  useEffect(() => {
    if (!Object.keys(scrolls).length) {

      const fetchProductsScrolls = async () => {
        try {
          const db = getFirestore(app);
          const docRef = doc(db, 'MainScreenEditables', 'ThreeScrolls');

          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            dispatch(setScrollsData(docSnap.data()));
          } else {
            console.log('Document not found!');
          }
        } catch (error) {
          console.log('Error getting document: ', error);
        }
      };

      fetchProductsScrolls();
    }
  }, [dispatch]);


  const classes = useStyles()
  const mdDown = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const shouldSideBarAppear = () => {
    if (!mdDown) {
      return <SideBar />
    }
  }
  const showLoadingScreen = !images.length;

  if (showLoadingScreen) {
    return <div style={{ width: "100%", height: "100vh", position: "relative", background: "#1f1a16" }}>
      <img src="animated_logo2.gif" alt="loading please wait" style={{ position: "absolute", left: "50%", top: "40%", transform: "translate(-50%,-40%)" }} />
    </div>;
  }
  const handlecallback = (type) => {
    history.push({
      pathname: `/prods/${type}`,
      state: { data: type }
    });
    window.scrollTo(0, 0);
  }
  return (
    <Grid container className={classes.root}>
      <Header background={header} />
      <Grid item xs={12} md={11} className={classes.pagination}>
        <Slider {...settings}>
          <PaginationElement styles={classes.carousel} image={images[0].image} title={images[0].title} type={images[0].type} description={images[0].description} callback={handlecallback} />
          <PaginationElement styles={classes.carousel} image={images[1].image} title={images[1].title} type={images[1].type} description={images[1].description} callback={handlecallback} />
          <PaginationElement styles={classes.carousel} image={images[2].image} title={images[2].title} type={images[2].type} description={images[2].description} callback={handlecallback} />
        </Slider>
      </Grid>
      <Grid item xs='auto' md={1} className={classes.sideBar}>
        {shouldSideBarAppear()}
      </Grid>
      <CustomDivider marginTop={'5%'} marginBottom={'5%'} />
      <ScrollingHorizontally data={sevenProducts} />
      <CustomDivider marginTop={'0%'} marginBottom={'5%'} />
      <HrScrollOptional name={scrolls.scroll3name} />
      <CustomDivider marginTop={'0%'} marginBottom={'5%'} />
      <HrScrollOptional name={scrolls.scroll2name} />
      <CustomDivider marginTop={'0%'} marginBottom={'5%'} />
      <HrScrollOptional name={scrolls.scroll1name} />
      <CustomDivider marginTop={'0%'} marginBottom={'0%'} />
      <FurnitureDescriptions />
      <div style={{ marginTop: "10%" }}><Footer /></div>

      <Button
        variant="contained"
        style={{
          background: '#c4b205',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 1000,
          borderRadius: '50%',
          minWidth: '56px',
          minHeight: '56px',
        }}
        onClick={showModal}
      ><SearchIcon style={{ color: 'white' }} />
      </Button>
      <Button
        variant="contained"
        style={{
          background: '#25D366', // WhatsApp green color
          position: 'fixed',
          bottom: '90px',  // Position above the search button
          right: '20px',
          zIndex: 1000,
          borderRadius: '50%',
          minWidth: '56px',
          minHeight: '56px',
        }}
        onClick={openWhatsApp}
      >
        <WhatsAppIcon style={{ color: 'white' }} />
      </Button>
      <SearchButtonModal isModalVisible={isModalVisible} close={() => { setIsModalVisible(false) }} />
    </Grid>

  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative"
  },

  information: {
    position: "absolute",
    left: 0,
    background: "transparent",
    marginTop: '2vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0vh',
      marginBottom: '0vh'
    }
  },
  pagination: {
    background: "transparent",
    marginTop: '1vh',
    [theme.breakpoints.down('md')]: {
      marginTop: '0vh',
      marginBottom: '0vh',
      padding: 0,
    },
    paddingRight: '0.5vw'
  },
  carousel: {
    height: '98vh',
    [theme.breakpoints.down('md')]: {
      height: '100vh'
    },
    position: 'relative',
    overflow: 'hidden', background: "transparent",
  },
  sideBar: {
    marginTop: "2vh"
  }
}));

export default HomeScreen
