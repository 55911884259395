const HomeDataReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_SEVEN_PRODUCTS':
      return action.payload;
    default:
      return state;
  }
};

const imagesReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_IMAGES_DATA':
      return action.payload;
    default:
      return state;
  }
};
const scrollsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SCROLLS_DATA':
      return action.payload;
    default:
      return state;
  }
};
const SubscrollsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_SCROLLS_SUB_DATA':
      return {
        ...state,
        [action.payload.scrollName]: action.payload.data,
      };
    default:
      return state;
  }
};
export { HomeDataReducer, imagesReducer,scrollsReducer,SubscrollsReducer };
