import firebase from 'firebase/compat/app';
import "firebase/compat/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCcYGUag7ZfcTtVCl3zVtVxFIVPienSkOo",
  authDomain: "vectorsnscalars-5b3d8.firebaseapp.com",
  databaseURL: "gs://vectorsnscalars-5b3d8.appspot.com/",
  projectId: "vectorsnscalars-5b3d8",
  storageBucket: "vectorsnscalars-5b3d8.appspot.com",
  messagingSenderId: "875905320725",
  appId: "1:875905320725:web:ece355c8ada35415fe4b1c",
  measurementId: "G-3YNC5TJJZR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const auth = getAuth(app);

export { auth, storage, app as default };


