import React, {useEffect, useState} from "react";
import Box from '@material-ui/core/Box'
import {Route, Switch} from 'react-router-dom'


import Header from "../components/Header";
import HomeScreen from "./HomeScreen/HomeScreen";
import AboutScreen from "./AboutScreen/AboutScreen";


const MainScreen = () => {

    

    return(
        <Box style={styles.root}>
            <Switch>
                <Route path='/' component={HomeScreen} exact/>
                <Route path='/about' component={AboutScreen}/>
            </Switch>
        </Box>
    )
}

const styles={
    root:{
        width:'100%',
    }
}


export default MainScreen
