import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

const MultipleImages = (Props) => {
  const [selectedImages, setSelectedImages] = useState(Props.datatoshow ? Props.datatoshow : []);
  const handleImageChange = (event) => {
    const newImages = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const newImage = event.target.files[i];
      newImage["id"] = Math.random();
      newImage["src"] = URL.createObjectURL(newImage); // Add a src property with the temporary URL
      newImages.push(newImage);
    }
    setSelectedImages((prevState) => [...prevState, ...newImages]);

    // setSelectedImages();
    Props.images(newImages)
  };

  return (
    <div>
        <Grid container spacing={2} >
         {selectedImages.length === 0 && (
            <Grid item xs={12}>
                <div style={{width:"100%",
                border:"1px solid white"
                ,display:"flex",
                justifyContent:"center"
                ,alignItems:"center",
                }}>
                    <Typography variant="body2" style={{color:"white",
                fontSize:"20px"}}>
                       Select more showable images 
                    </Typography>
                </div>
            </Grid>
          )}
          </Grid>
          <div style={{ marginTop: '16px' }}>
     
            <Grid container spacing={2}  style={{border:"1px solid white"
                    ,height:"300px",
                    overflowY:"auto"}}> 
            {selectedImages.map((imageSrc, index) => (
                <Grid item xs={3} key={index}>
                <img
                    src={imageSrc.src || imageSrc}
                    alt={`Selected ${index + 1}`}
                    style={{ maxWidth: '100%' }}
                />
                </Grid>
            ))}
            
            </Grid>
        </div>
          <div style={{width:"100%",textAlign:"Center",marginTop:"5%",marginBottom:"20%"}}>
            <input
                accept="image/*"
                type="file"
                multiple
                style={{ display: 'none' }}
                id="image-input"
                onChange={handleImageChange}
            />
            <label htmlFor="image-input">
                <Button variant="contained" style={{color:"white",background:"#c4b205"}} component="span">
                <AddIcon /> Select Images
                </Button>
            </label>
      </div>
    
    </div>
  );
};

export default MultipleImages;
