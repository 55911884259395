import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Card, CardHeader, CardContent,CardMedia,CardActions, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import OpenProducts from './openproducts';

function ProductGrid({ data ,onclickingMenuItem,callback,refresh}) {

  const [products,setProducts] = useState([])
  const [openproduct , setopenproduct] = useState(false)
  const [showgrid, setshowgrid] = useState(true)
  const [productdata , setproductdata] = useState([])
  const classes = useStyles();

  useEffect(()=>{
    setProducts(data)
    if (onclickingMenuItem===true){
      setopenproduct(false)
      setshowgrid(true)
    }
  },[data])
  const handleCallback=()=>{
    callback()
  }
  const handleOpeningBtn = (index) => {
    setshowgrid(false)
    setopenproduct(true)
    setproductdata(data[index])
  };
  const handleGoback = (type)=>{
    setshowgrid(true)
    setopenproduct(false)
    refresh(type)
  }

  return (
    <>
    {showgrid && (<Grid container spacing={2}>
      {products.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <div style={{background:"rgba(80, 80, 80, 0.5)",borderRadius:"3%",marginBottom:"2%"}}>
          <Button style={{ width: '100%' ,height:"100%",padding:"0"}} onClick={() => handleOpeningBtn(index)}>
            <Card className={classes.cards}>
              <CardMedia
              style={{padding:"5%",borderRadius:"10%"}}
                  component="img"
                  alt="Card Image"
                  width="150"
                  image={item.image}
                  title="Card Image"
              />
            <div >
                <CardHeader title={item.title}  style={{textAlign:"left"}}  titleTypographyProps={{ variant: 'h6', className: classes.title }}/>
                <CardContent style={{paddingTop:0,display:"flex",alignItems:"flex-end"}}>
                  <div style={{display:"flex",flexDirection:"row",width:"100%"}}>
                    <Typography className={classes.price} variant="body`" component="p">
                    {item.warranty === 0 ? '' : `Warranty ${item.warranty} years`}
                    </Typography>
                    <Typography className={classes.price}variant="body1" style={{  color:"#c4b205",}} component="p">
                      Rs : {item.price}
                    </Typography>
                  </div>
                </CardContent>
            </div>
            </Card>
          </Button>
          </div>
        </Grid>
      ))}
    </Grid>
  )}
  
  {openproduct && <OpenProducts data={productdata} goBack={(type)=>{handleGoback(type)}} callback={handleCallback}  />}
  </>
  );
  
}

const useStyles = makeStyles((theme) => ({
  paper: {
    background: 'yellow',
    textAlign: 'center',
  },
  container: {
    background: 'red',
    borderRadius: '5%',
  },
  cards: {
    background: "transparent",
    color:"white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },

title:{
  textAlign:"left",
    borderBottom:"1px solid grey",

},
price:{

  width:"50%"
}
}));

export default ProductGrid;
