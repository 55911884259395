const ProjectDataReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_ALL_PROJECTS':
        return action.payload;
      default:
        return state;
    }
  };
  
  const OtherInfoReducer = (state = [], action) => {
    switch (action.type) {
      case 'SET_OTHER_INFO':
        return action.payload;
      default:
        return state;
    }
  };

  
export { ProjectDataReducer, OtherInfoReducer };
  