export const setAdminActive = (isAdminActive) => {
    return {
      type: 'SET_ADMIN_ACTIVE',
      payload: isAdminActive,
    };
  };
  
  
  
  
  
  