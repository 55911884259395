const initState = {}

const productReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_PRODUCT':
            return {...state, status: 'success'}
        case 'CREATE_PRODUCT_ERROR':
            console.log('create product error', action.error)
            return {...state, status: 'error'}
        case 'SET_STATUS_NOSUCCESS': // New case to set status to 'nosuccess'
            return { ...state, status: 'nosuccess' };
        default:
            return state
    }
}

export default productReducer
