export const setSevenProducts = (products) => {

  return {
    type: 'SET_SEVEN_PRODUCTS',
    payload: products,
  };
};
export const setImagesData = (imagesData) => {
  return {
    type: 'SET_IMAGES_DATA',
    payload: imagesData,
  };
};
export const setScrollsData = (scrollsData) => {
  return {
    type: 'SET_SCROLLS_DATA',
    payload: scrollsData,
  };
};

export const setScrollsSubData = (scrollName, data) => {
  return {
    type: 'SET_SCROLLS_SUB_DATA',
    payload: { scrollName, data },
  };
};