import React, { useState ,useEffect} from 'react';
import { TextField, Typography, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {withStyles,} from '@material-ui/core/styles';
import { Star } from '@material-ui/icons';
import { getFirestore, collection,addDoc, doc,deleteDoc, updateDoc } from 'firebase/firestore';
import app from '../../../config/fbConfig';
function ProjectInfo() {
    const db = getFirestore(app);
    const classes = useStyles();
  const [projectsFinished, setProjectsFinished] = useState('');
  const [yearsOfExpertise, setYearsOfExpertise] = useState('');
  const [yearsSinceCompanyStarted, setYearsSinceCompanyStarted] = useState('');
  const [isloading,setisloading] = useState(false)

  const handleProjectsFinishedChange = (event) => {
    setProjectsFinished(event.target.value);
  };

  const handleYearsOfExpertiseChange = (event) => {
    setYearsOfExpertise(event.target.value);
  };

  const handleYearsSinceCompanyStartedChange = (event) => {
    setYearsSinceCompanyStarted(event.target.value);
  };
    const updateProjectData = async () => {
        setisloading(true)
      try {
        const collectionRef = collection(db, 'MainScreenEditables');
        await updateDoc(doc(collectionRef, 'ProjectNumbers'), {

          numberOfProjects: projectsFinished,
          yearsOfExpertise: yearsOfExpertise,
          yearsSinceCompanyStarted: yearsSinceCompanyStarted
        });
        setisloading(false)
        alert('succesfully uploaded')
        console.log('Document successfully updated!');
      } catch (error) {
        console.error('Error updating document:', error);
      }
    };
  
  return (
    <div className={classes.container}>
       <div style={{display:"flex",flexDirection:"row"}}><Star style={{color:"white"}}/><Typography variant="h6" className={classes.headings}>EnterNumber of Projects Finished</Typography></div>

        <TextField
        className={classes.fields}
            variant="outlined"
            type="number"
            margin="normal"
            required
            fullWidth
            id="id1"
            label="Number of Projects Finished"
            name="years"
            autoComplete="years"
            autoFocus
            value={projectsFinished}
            onChange={handleProjectsFinishedChange}
            />
      <div style={{display:"flex",flexDirection:"row"}}><Star style={{color:"white"}}/><Typography variant="h6" className={classes.headings}>Enter Number of Years of Expertise</Typography></div>

        <TextField
        className={classes.fields}
            variant="outlined"
            type="number"
            margin="normal"
            required
            fullWidth
            id="id2"
            label="Number of years of expertise"
            name="years"
            autoComplete="years"
            autoFocus
            value={yearsOfExpertise}
            onChange={handleYearsOfExpertiseChange}
            />
      <div style={{display:"flex",flexDirection:"row"}}><Star style={{color:"white"}}/><Typography variant="h6" className={classes.headings}>Enter Number of Years Since Your Company Started</Typography></div>
 
        <TextField
        className={classes.fields}
            variant="outlined"
            type="number"
            margin="normal"
            required
            fullWidth
            id="id3"
            label="Number of years since company started"
            name="years"
            autoComplete="years"
            autoFocus
            value={yearsSinceCompanyStarted}
            onChange={handleYearsSinceCompanyStartedChange}
            />        
        {isloading && <div style={{display:"flex",justifyContent:"center"}}>
            <CircularProgress style={{color:"#c4b205"}}/>
        </div>}
        <Button style={{background:"#c4b205",color:"white",marginTop:"2%"}} onClick={updateProjectData}>Save</Button>

    </div>
  );
}
const useStyles = makeStyles((theme) => ({
    container:{
        display:"flex",
        justifyContent:"center",
        flexDirection:"column",
        border:"2px solid white",
        borderRadius:"3%",
        padding:"5%"
    },
    headings:{
    color:"white",
    paddingLeft:"5px"
},
    titleInput: {
    width: '75%',
    margin: '2%'
},
inputText: {
    color: 'white',
},
fields:{
        '& label.Mui-focused': {
            color: '#c4b205',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#c4b205',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c4b205',
            },
        },
    },
}));
export default ProjectInfo;
