import React, { useEffect, useState, useRef } from 'react';
import './stylinghrscroll.css';
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core/';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const ScrollingHorizontally = (props) => {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const repeatComponent = new Array(5).fill(null);
  const mdDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const containerRef = useRef(null);

  const [isFullyScrolledRight, setIsFullyScrolledRight] = useState(false);

  const scrollForward = () => {
    const container = containerRef.current;
    const scrollAmount = container.clientWidth;
    const duration = 500;

    const start = container.scrollLeft;
    const end = start + scrollAmount;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);

      container.scrollLeft = start + progress * (end - start);

      if (progress < 1) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  };

  const scrollBackward = () => {
    const container = containerRef.current;
    const scrollAmount = container.clientWidth;
    const duration = 500;

    const start = container.scrollLeft;
    const end = start - scrollAmount;
    const startTime = performance.now();

    function scroll(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);

      container.scrollLeft = start - progress * (start - end);

      if (progress < 1) {
        window.requestAnimationFrame(scroll);
      }
    }

    window.requestAnimationFrame(scroll);
  };

  const handleScroll = () => {
    const container = containerRef.current;
    const isAtEnd = container.scrollWidth - container.clientWidth === container.scrollLeft;
    setIsFullyScrolledRight(isAtEnd);
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => container.removeEventListener('scroll', handleScroll);
  }, []);

  const [style, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)"
  }));

  const bind = useScroll((event) => {
    set({
      transform: `perspective(500px) rotateY(${event.scrolling ? event.delta[0] * 0.3 : 0}deg)`,
    });
  });

  const handleImageClick = (index) => {
    history.push({
      pathname: `/products/${index.title.replace(/\s/g, '-').toLowerCase()}`,
      state: { data: index }
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='recent-container'>
        <ArrowForward style={{ fontSize: 40, color: '#c4b205' }} />
        <Typography className='recent' style={{ color: "#c4b205", fontWeight: '600', fontFamily: 'Tahoma  ' }} variant='h4'>
          Recent
        </Typography>
      </div>
      <div className="container" {...bind()} ref={containerRef}>
        {props.data ? (
          props.data.map((index, i) => (
            <div style={{ marginTop: isMobile ? "10px" : `${i % 2 === 0 ? 0 : 20}px` }}>
              <div key={index.image} onClick={() => handleImageClick(index)}>
                <animated.div
                  className="card"
                  style={{
                    ...style,
                    backgroundImage: `url(${index.image})`,
                    cursor: "pointer",
                  }}
                />
                <h3 className="capitalize-first-letter" style={{ textAlign: "center", color: "white", fontWeight: "lighter" }}>{index.title}</h3>
              </div>
            </div>
          ))
        ) : (
          repeatComponent.map((_, index) => (
            <div style={{ marginTop: isMobile ? "10px" : `${index % 2 === 0 ? 0 : 20}px` }}>
              <div key={index} {...bind()}>
                <animated.div
                  className="card"
                  style={{
                    ...style,
                    backgroundImage: null,
                    cursor: "pointer",
                  }}
                >
                  <SkeletonTheme baseColor="#e0e0e0" highlightColor="#c4b205">
                    <Skeleton height={200} style={{ marginTop: isMobile ? "10px" : `${_ % 2 === 0 ? 0 : 20}px` }} />
                  </SkeletonTheme>
                </animated.div>
              </div>
            </div>
          ))
        )}
      </div>
      {!mdDown && (
        <div style={{ position: "relative", paddingLeft: "5px" }}>
          {isFullyScrolledRight ? (
            <ArrowBack className='arrowforward' style={{ fontSize: "2.0em" }} onClick={scrollBackward} />
          ) : (
            <ArrowForward className='arrowforward' style={{ fontSize: "2.0em" }} onClick={scrollForward} />
          )}
        </div>
      )}
    </>
  );
};

export default ScrollingHorizontally;
