import CustomSelect from './Customselect'

const ScrollComponent = () => {

  return (
    <div>
        <CustomSelect/>
    </div>
  );
};

export default ScrollComponent;
