const HomePageContent={
    Outdoortitle:"Elevate Your Outdoor Retreat - Unwind with Stylish and Durable Outdoor Furniture",
    Outdoordescription:`Transform your outdoor spaces into stylish retreats at our outdoor furniture haven. Our diverse collection combines craftsmanship and quality to suit your unique tastes, offering elegant patio sets and comfortable loungers for every outdoor occasion. We prioritize sustainability, reducing our ecological footprint with eco-conscious materials. Experience a seamless blend of functionality and style, redefining outdoor living and embracing nature's beauty in your sanctuary. Trust us to enrich your outdoor living experience, creating a harmonious outdoor haven that seamlessly integrates with your lifestyle.`
    ,
    Indoortitle:"Redefine Your Indoor Haven - Discover Timeless and Versatile Indoor Furniture ",
    Indoordescription:`Discover timeless elegance and versatility at our indoor furniture gallery, redefining your living spaces. Our carefully crafted pieces offer comfort and style, from plush sofas to chic dining sets, catering to your unique preferences. We prioritize innovation and quality, ensuring our furniture stands the test of time. Experience the fusion of style and practicality in our collections, adapting to changing interior decor trends. Let us be your trusted partner in curating spaces that reflect your lifestyle, offering warmth and lasting joy. Redefine your indoor haven with furniture that blends comfort, aesthetics, and functionality seamlessly.
    `, 
    'Car Parks':
    `We are engaged in offering a high quality Car Parking Tensile Structure that known for its easy installation and low maintenance quality. We offer these structures in different standard designs and dimensions to make our clients satisfied. Designed for efficient area coverage, like residential car parking, commercial car parking, these tensile structures protect the vehicles from harsh sunlight and damage.
    We are engaged in offering a high uality Car Parking Tensile Structure that is known for its easy installation and low maintenance quality. We offer these structures in different standard designs and the vehicles from harsh sunlight and dimensions to make our clients satisfied. Designed for efficient area coverage, like residential car parking, commercial car parking, these tensile structures pro
    damage.`,
    'Wood Plastic Composite': `
    Wood-plastic composites (WPCs) are composite materials made of wood fiber/wood flour and thermoplastic(s) such as PE, PP, PVC, or PLA. The material can be sawed, cut, planed, nailed, and the performance of the product can be compared with hardwood products.`
    ,
    'Sail Shades':`
    Sail Shades protect and shade you from the severe effects of the sun's heat & UV rays. Some areas of the world call them Sun Sails. A sail can be mounted to your home, large trees, patio/deck columns or they can be flown from wood posts or steel columns embedded in concrete footings. These structures are often square rectangular or triangular in shape. Depending on the effect that you want, each shape will provide a different look or feel. Sails can be overlapped in different arrangements, heights, and combinations to provide a beautiful visual effect.`
    ,
    'Awnings':`
    An Awning or overhang is a secondary covering attached to the exterior wall of a building it is typically composed of canvas woven of acrylic that is stretched tightly over a light structure of Aluminium, iron or steel Retractable Awnings let owners control the weather on their own terms. When passit, showers threaten, or when the sun gets hot, they or the home automation systern unroll the Awning for ne protection and shade. They can help prevent carpets and furniture from fading in sunlight Awnings also provide a sheltered place for children and pets to play, shielded from dirt sun.`
    ,'Retractable Structures':
    `Retractable structures offer versatility in outdoor spaces. They allow you to adjust the coverage according to your preferences. With an easy-to-use retractable mechanism, these structures can provide shade, shelter, or an open-air experience. They are suitable for various settings, from residential patios to commercial outdoor seating areas.
`    
    ,'Garden Furniture':
    `Enhance your outdoor living with our garden furniture collection. Crafted for comfort and durability, our garden furniture pieces are designed to withstand outdoor conditions while adding aesthetic appeal to your outdoor space. Create a cozy and inviting atmosphere for relaxation, dining, and social gatherings.
   ` 
    ,'Fixed Structures':
    `Fixed shades provide a permanent solution for sun protection and outdoor comfort. These structures are carefully designed to seamlessly integrate with your surroundings while offering consistent shading. Fixed shades are ideal for creating defined outdoor areas that can be enjoyed year-round, making the most of your outdoor space.
`
    ,'Wooden & Vinyl Flooring':
    `Transform your indoor space with our range of wooden and vinyl flooring options. Whether you prefer the classic charm of wood or the versatility of vinyl, our flooring choices combine aesthetics with practicality. Easy to maintain and available in various styles, they add warmth and character to any room.
`,
'Blinds':
    `Elevate your interior design with our selection of blinds. Blinds offer privacy, light control, and style to your windows. From sleek roller blinds to elegant Venetian blinds, we have options to suit your décor preferences. Enhance the ambiance of your living spaces while enjoying the practical benefits of adjustable shading.
`
  ,   'Indoor Furniture':
   ` Discover indoor furniture pieces that blend comfort and aesthetics. Our collection includes a variety of styles, from contemporary to traditional, designed to complement your interior design. Whether you're furnishing your living room, dining area, or bedroom, our indoor furniture combines functionality with visual appeal.
`
  ,  'Wallpaper & Wallpanels':
    `Create captivating interiors with our wallpaper and wall panel offerings. From vibrant patterns to subtle textures, our wallpapers allow you to express your personality and transform plain walls into focal points. Wall panels add dimension and visual interest, allowing you to experiment with unique designs and create stunning accent walls.
`,
'Artificial Grass':`Experience the beauty of nature without the maintenance with our premium artificial grass solutions. Our artificial grass offers a lush and vibrant alternative to natural grass, providing a perfect green space all year round. Designed to withstand varying weather conditions, our artificial grass is suitable for a range of applications, from residential lawns to commercial landscapes.`
,
'Swings':`
Swings are a timeless addition to any outdoor space, offering relaxation and a touch of nostalgia. Whether hung from a tree branch or a dedicated stand, they provide a gentle sway that soothes the soul.

Swings come in various materials and styles, from classic wooden porch swings to modern, sleek designs that elevate outdoor aesthetics.`
,
'Pool Beds':`
Pool beds are the epitome of luxury by the water. These oversized loungers offer a comfortable oasis for sunbathing or simply unwinding beside the pool, making every swim session feel like a vacation.

Designed for both relaxation and style, pool beds often feature weather-resistant materials and cushioning, ensuring comfort and durability in a poolside setting.`
,
'Outdoor Heaters':`
Outdoor heaters extend the usability of outdoor spaces beyond warm summer days. They emit comforting warmth, allowing you to enjoy al fresco dining and gatherings even on chilly evenings.

Whether in the form of freestanding propane heaters or wall-mounted electric units, outdoor heaters are essential for creating a cozy ambiance in your outdoor oasis.`
,
'Sofas':`
Outdoor sofas provide the perfect blend of comfort and functionality for your outdoor living area. They are versatile pieces of furniture, ideal for lounging, entertaining, or even taking an afternoon nap in the fresh air.

With a wide range of designs, materials, and cushion options, outdoor sofas can be customized to suit your style and outdoor space.`
,
'Dining':`
Outdoor dining sets create a welcoming atmosphere for sharing meals with family and friends under the open sky. They come in various sizes and styles, from compact bistro sets for intimate gatherings to spacious dining tables for large parties.

Durable and weather-resistant, outdoor dining furniture ensures that every meal outdoors is a memorable and enjoyable experience.`
}

export default HomePageContent