import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import FOG from "vanta/dist/vanta.fog.min.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AnmatedNumbers from "../Animatednumbers/animatednumbers";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core';

const InteractiveBackground = (props) => {
  const vantaRef = useRef(null);
  const isMobile = useMediaQuery('(max-width:601px)');
  useEffect(() => {
    const vantaEffect = FOG({
      el: vantaRef.current,
      THREE: THREE,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 100.0,
      minWidth: 100.0,
      highlightColor: 0x757575,
      midtoneColor: 0xfff005,
      lowlightColor: 0xe8e717,
      baseColor: 0x828282,
      size: 0.80, speed: 1.70
    });

    return () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };
  }, []);

  const classes = useStyles();
  return (
    <div style={{margin:"5%"}}>
    <div style={{ height: isMobile?"100vh":"50vh",  position: "relative" }} ref={vantaRef}>
         <div className={classes.root} style={{display:"flex",justifyContent:"center"}}>
          <Grid container spacing={3} >
            {/* For larger screens (3 columns) */}
            <Grid item xs={12} sm={4} className={classes.grid}>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <AnmatedNumbers years={props.data.numberOfProjects}/>
                <span style={{color:"white",fontWeight:"bold",fontSize:50,paddingBottom:"50px"}}>
                  +
                </span>
              </div>
              <Typography variant="h4" gutterBottom className={classes.numbers} style={{fontFamily:isMobile?"arial":"cursive"}}>
              Projects Finished
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <AnmatedNumbers years={props.data.yearsOfExpertise}/>
                <span style={{color:"white",fontWeight:"bold",fontSize:50,paddingBottom:"50px"}}>
                  +
                </span>
              </div>
              <Typography variant="h4" gutterBottom className={classes.numbers} style={{fontFamily:isMobile?"arial":"cursive"}}>
                Years of Expertise
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.grid}>
              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <AnmatedNumbers years={props.data.yearsSinceCompanyStarted}/>
                <span style={{color:"white",fontWeight:"bold",fontSize:50,paddingBottom:"50px"}}>
                  +
                </span>
              </div>
              <Typography variant="h4" gutterBottom className={classes.numbers} style={{fontFamily:isMobile?"arial":"cursive"}}>
                Years since Vectors & scalars
              </Typography>
            </Grid>
          </Grid>
        </div>
    </div>
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
       top: "50%", 
      transform: "translateY(-50%)" ,
      zIndex:"1"
    },
    grid:{
      textAlign:"center",
    },  numbers:{
        color:"white",
        marginTop:'5%',
      },
}));
export default InteractiveBackground;
