export const SetAllProjects = (projects) => {
    return {
      type: 'SET_ALL_PROJECTS',
      payload: projects,
    };
  };
  export const SetOtherInfo = (info) => {
      return {
        type: 'SET_OTHER_INFO',
        payload: info,
      };
    };
