import ProjectForm from "./adminProjectHandler";
const OpenProjectAdmin = (props) => {

  return (
        <div>
            <ProjectForm showdelete={true} goback={props.goback} projectdata={props.projectdata} savetype={'Update'} />

        </div>
  );
};

export default OpenProjectAdmin;
