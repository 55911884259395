import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useMediaQuery,useTheme} from '@material-ui/core/';

const TempImage = (props) => {
    const [isclicked,setislicked] = useState(false)
    const [showclose , setshowclose] = useState(false)
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const handleclicked = ()=>{
    if (!isTablet){
    setislicked(!isclicked)
    setshowclose(!showclose)
}
  }
  const zoomedStyles = isclicked 
    ? {
        transform: 'scale(2)', // Increase the scale for zoomed effect
        transition: 'transform 0.3s ease-in-out',
        cursor: 'zoom-out',
      }
    : {
        transition: 'transform 0.3s ease-in-out',
        cursor: 'zoom-in',
      };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        height: props.height||'75vh',
        width:props.width ||'auto' 
      }}
    >
      <img
        src={props.hoverimage}
        alt={'no img'}
        style={{
          ...zoomedStyles,
          maxWidth: '100%',
          maxHeight: '100%',
          border: '2px solid white',
          height: '100%',
          overflowX:"auto",
          overflowY:"auto"
        }}
        onClick={handleclicked}
      />

        {!showclose && <IconButton
          edge="end"
          color="inherit"
          onClick={props.close}
          style={{
            position: 'absolute',
            top: -30,
            right: -30,
            zIndex: 11,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>}
    </div>
  );
};

export default TempImage;
