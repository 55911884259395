import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';

const Footer = ({isFetching,Callback,Callbackdelete,productname,goingback,showdelete}) => {
  const [openDialog, setOpenDialog] = useState(false);
  
  const handleDeleteProduct = () => {
    Callbackdelete()
    setOpenDialog(false);
  };

  const handleSaveProduct = () => {
    Callback()
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
const handleback = ()=>{
  goingback()
}
  return (
    <AppBar position="fixed"  style={{ top: 'auto', bottom: 0 ,backgroundColor:"#c4b205"}}>
      <Toolbar>

        <Typography variant="body1" style={{ flexGrow: 1 }}>
        {productname}
        </Typography>
        {isFetching ? (
      <CircularProgress style={{color:"grey"}}/> // Render the loader component when isFetching is true
    ) : (<IconButton edge="end" color="inherit" onClick={handleSaveProduct}>
          <SaveIcon />
        </IconButton>
        )}
        {showdelete && <IconButton edge="end" color="inherit" onClick={handleOpenDialog}>
          <DeleteIcon />
        </IconButton>}
        <IconButton edge="end" color="inherit" onClick={(handleback)}>
        <ClearIcon />
      </IconButton>
      </Toolbar>  
      
      

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{color:"white"}}>Delete Product</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete this product?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} style={{color:"white"}}>
            Cancel
          </Button>
          <Button onClick={handleDeleteProduct} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default Footer;
