import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../../containers/AdminScreen/openProductFooter';
import MultipleImages from './MultipleImages';
import { storage } from '../../config/fbConfig';
import { getFirestore, collection, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { getFirebase } from 'react-redux-firebase';
import imageCompression from 'browser-image-compression';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
    fontWeight: 'lighter',
    marginBottom: '5%',
  },
  inputField: {
    border: '1px solid white',
    borderRadius: '2px',
  },
  emptyImageContainer: {
    height: '200px',
    width: '100%',
    border: '2px dashed white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '20px',
    marginBottom: '2%',
  },
  selectedImageContainer: {
    textAlign: 'center',
    marginBottom: '2%',
  },
  selectedImage: {
    height: '300px',
  },
  imageButton: {
    background: '#c4b205',
    color: 'white',
    marginTop: '2%',
    marginBottom: '2%',
  },
  footerContainer: {
    marginTop: '40%',
  },
}));

const ProjectForm = (Props) => {
  const classes = useStyles();
  const [title, setTitle] = useState(Props.projectdata?.title || '');
  const [description, setDescription] = useState(Props.projectdata?.description || '');
  const [selectedImage, setSelectedImage] = useState(Props.projectdata?.image || null);
  const [location, setLocation] = useState(Props.projectdata?.location || '');
  const [otherImages, setotherImages] = useState([])
  const [Dp, setDp] = useState(Props.projectdata?.image || null)
  const [urls, setUrls] = useState([])
  const [IsFetching, setIsFetching] = useState(false)
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  const handleImageChange = async (e) => {
    console.log(e.target.files[0])
    setDp(e.target.files[0])
    const file = e.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
        };
        const compressedFile = await imageCompression(file, options);

        // Convert the compressed file to a base64 string
        const reader = new FileReader();
        reader.onload = (event) => {
          setSelectedImage(event.target.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error compressing image:', error);
      }
    }
  };
  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  const handlemultipleImages = (value) => {
    setotherImages(value)
  }
  const uploadData = async () => {
    setIsFetching(true)
    console.log([Dp, ...otherImages])
    const promises = [Dp, ...otherImages].map((image) => {
      console.log('abcd', image)
      let uploadTask;
      if (image.name) {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);

        return uploadTask
          .then(() => {
            return storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then((url) => {
                return url;
              })
              .catch((error) => {
                console.error("Error getting download URL:", error);
                throw error; // Rethrow the error to propagate it
              });
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
            throw error; // Rethrow the error to propagate it
          });
      } else {
        return Promise.resolve(image); // Return a resolved promise if no image name
      }
    });
    Promise.all(promises)
      .then((downloadUrls) => {
        setUrls((prevState) => [...prevState, ...downloadUrls]);
        if (Props.savetype === 'Save') {
          handleUploadalldata(downloadUrls)
        }
        else {
          updateData(downloadUrls)
        }
      })
      .catch((err) => {
        console.log("Error in Promise.all:", err);
        // Handle the error as needed
      });
  }
  const handleUploadalldata = (downloadUrls) => {
    try {
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');
      const product = {
        image: downloadUrls[0],
        title: title,
        title_lowercase: title.toLowerCase(),
        description: description,
        location: location,
        moreimages: downloadUrls.slice(1),
        type: "Project",
        date: Date.now()
      };
      addDoc(productsCollection, product)
        .then((newDocRef) => {
          alert("All images uploaded");
          Props.goback()
          setIsFetching(false)
        })
        .catch((error) => {
          console.error('Error creating new document:', error);
        });

      // setIsFetching(false); // Remove this line, it's not necessary here
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  const updateData = (downloadUrls) => {
    try {
      const existingMoreImages = Props.projectdata.moreimages || []; // Existing moreimages array

      // Function to get the base URL without query parameters
      const getBaseUrl = (url) => {
        try {
          const parsedUrl = new URL(url);
          return parsedUrl.origin + parsedUrl.pathname; // Base URL without query parameters
        } catch (error) {
          console.error('Invalid URL:', url);
          return '';
        }
      };

      // Normalize existingMoreImages to base URLs
      const existingBaseUrls = existingMoreImages.map(getBaseUrl);

      // Ensure that each URL in downloadUrls is only added if its base URL doesn't already exist in existingBaseUrls
      const updatedMoreImages = [...existingMoreImages]; // Start with the existing more images
      downloadUrls.forEach((url) => {
        const baseUrl = getBaseUrl(url);
        const existingBaseUrl = existingBaseUrls.includes(baseUrl);

        if (!existingBaseUrl) {
          // Only add the new URL if its base URL doesn't already exist in existingMoreImages
          updatedMoreImages.push(url);
          existingBaseUrls.push(baseUrl); // Add base URL to prevent duplicates
        }
      });

      console.log('Updated More Images:', updatedMoreImages);


      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');

      const productUpdates = {
        image: downloadUrls[0],
        title: title,
        description: description,
        location: location,
        moreimages: updatedMoreImages.slice(1), // Exclude the first image for moreimages
        type: "Project",
        date: Date.now(),
        title_lowercase: title.toLowerCase()
      };

      const productRef = doc(productsCollection, Props.projectdata.id); // Reference to the specific document
      updateDoc(productRef, productUpdates) // Update the document
        .then(() => {
          alert("All images uploaded");
          Props.goback();
          setIsFetching(false);
        })
        .catch((error) => {
          console.error('Error updating document:', error);
        });
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };
  const handledelProject = async () => {
    try {
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');
      const productRef = doc(productsCollection, Props.projectdata.id); // Reference to the specific document
      deleteDoc(productRef) // Delete the document
        .then(() => {
          // You can add further actions after successful deletion if needed
          Props.goback()
        })
        .catch((error) => {
          console.error('Error deleting document:', error);
        });
    } catch (error) {
      console.error('Error deleting document:', error);
    }
    const propsAllImages = [Props.projectdata?.image, ...Props.projectdata?.moreimages];
    console.log('these are all images', propsAllImages)
    if (propsAllImages && Array.isArray(propsAllImages)) {
      console.log('inside');
      for (const imageUrl of propsAllImages) {
        const imageRef = storage.refFromURL(imageUrl);
        try {
          await imageRef.delete();
        } catch (error) {
          console.error(`Error deleting image ${imageUrl}:`, error);
        }
      }
    }

  };

  useEffect(() => {
  }, [urls])
  return (
    <Container maxWidth="md">
      <Typography variant="h4" className={classes.title} gutterBottom>
        Add Project
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.inputField}
              label="Project Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.inputField}
              label="Project Description"
              variant="outlined"
              fullWidth
              multiline
              rows={10}
              value={description}
              onChange={handleDescriptionChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.inputField}
              label="Location"
              variant="outlined"
              fullWidth
              value={location}
              onChange={handleLocationChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} className={classes.selectedImageContainer}>
            {!selectedImage && (
              <div className={classes.emptyImageContainer}>
                Select Project Display Picture
              </div>
            )}
            {selectedImage && (
              <Card>
                <CardMedia
                  className={classes.selectedImage}
                  component="img"
                  alt="Selected Project"
                  image={selectedImage}
                />
              </Card>
            )}
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="image-upload"
              type="file"
              onChange={handleImageChange}
            />
            <label htmlFor="image-upload">
              <Button
                className={classes.imageButton}
                variant="contained"
                component="span"
              >
                Browse Image
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} >
            <MultipleImages images={handlemultipleImages} datatoshow={Props.projectdata?.moreimages || []} />
          </Grid>
          <div className={classes.footerContainer}>
            <Footer
              goingback={Props.goback} Callback={uploadData}
              showdelete={Props.showdelete}
              isFetching={IsFetching}
              Callbackdelete={handledelProject}
            />
          </div>
        </Grid>
      </form>
    </Container>
  );
};

export default ProjectForm;
