
import { Grid } from '@material-ui/core'
import Company from "./Company/Company";
import { makeStyles } from "@material-ui/core/styles";
import Links from "./Links/Links";
import Contact from "./Contact/Contact";
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import Project from './Projects/Project/Project';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    getFirestore,
    collection,
    getDocs,
    where, query,
    orderBy,
    limit,
} from 'firebase/firestore';
import app from '../../config/fbConfig';

const Footer = ({ backgroundColor }) => {
    const classes = useStyles()
    const [projects, setProjects] = useState([]);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        console.log("request project made");
        const fetchProductsData = async () => {
            setloading(true)
            try {
                const db = getFirestore(app);
                const collectionRef = collection(db, 'products');

                // First query to get documents where 'type' is not equal to 'Project'
                const filteredProductQuery = query(collectionRef, where('type', '==', 'Project'));

                // Change the order of the queries to match the 'type' inequality filter
                const orderedProductQuery = query(filteredProductQuery, orderBy('type'), orderBy('date', 'desc'));

                // Limit the number of documents fetched to 7
                const limitedProductQuery = query(orderedProductQuery, limit(2));

                const snapshot = await getDocs(limitedProductQuery);
                const products = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                console.log(products)
                setProjects(products);
                setloading(false)
            } catch (error) {
                console.log('Error getting documents: ', error);
            }
        };

        fetchProductsData(); // Call the function to fetch and update the products data
    }, []);
    return (
        <Grid container className={classes.container} style={{ backgroundColor: backgroundColor ? backgroundColor : '', zIndex: 2 }}>
            <Grid item xs={12} md={3} lg={3} xl={3} className={classes.footerItem}>
                <Company />
            </Grid>

            <Grid item xs={12} md={3} lg={3} xl={3} className={classes.footerItem}>
                <Contact />
            </Grid>
            <Grid item xs={12} md={3} lg={3} xl={3} className={classes.footerItem}>
                <div style={{ paddingLeft: "10px", paddingRight: "10px", textAlign: "Center" }}>
                    <Typography variant='h5' style={{ color: "white", fontWeight: "bold" }}>
                        Recent Projects
                    </Typography>
                    <div>
                        {loading ? (
                            // Show a loader when loading is true
                            <div style={{ display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: "#c4b205" }} /></div>
                        ) : (
                            // Show projects when loading is false
                            projects.map((project) => (
                                <Box style={{ marginTop: '4vh' }} key={project.id}>
                                    <Project
                                        image={project.image}
                                        title={project.title}
                                        location={project.location}
                                        category={project.description}
                                        data={project}
                                    />
                                </Box>
                            ))
                        )}
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} md={3} lg={3} xl={3} className={classes.footerItem}>
                <Links />
            </Grid>
        </Grid >
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background,
        width: '100%',
    },
    footerItem: {
        padding: "1%",
        marginTop: '15vh',
        marginBottom: '15vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5vh',
            marginBottom: '0vh',
        },
    }
}));

export default Footer;
