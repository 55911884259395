import React, { useState } from 'react';
import { CardMedia, IconButton, Button, Grid, Typography, TextField, Slider, CircularProgress, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { useEffect } from 'react';
import 'firebase/storage';
import { storage } from '../../config/fbConfig';
import { getFirestore, collection, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { getFirebase } from 'react-redux-firebase';
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from '@material-ui/lab';
import Footer from './openProductFooter'
import imageCompression from 'browser-image-compression';

function OpenProducts(props) {
  const classes = useStyles();
  const [sliderValue, setSliderValue] = useState(props.data.warranty); // State for slider value
  const [inputPropsStyles, setInputPropsStyles] = useState({});
  const [newdp, setnewdp] = useState(props.data.image); // State for title
  const [title, setTitle] = useState(props.data.title); // State for title
  const [description, setDescription] = useState(props.data.description); // State for description
  const [images, setImages] = useState([]); // State for images
  const isSmallScreen = window.innerWidth <= 600;
  const [urls, setUrls] = useState(props.data.moreimages || []);
  const [progress, setProgress] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [updateSuncess, setupdateSuncess] = useState(false)
  const [runUpdate, setrunUpdate] = useState(false)
  const [IsSelectedtwo, setIsSelectedtwo] = useState(false)
  const [Isclick, setIsclick] = useState(null)
  const [text, setText] = useState('')
  const [Price, setPrice] = useState(props.data.price)
  const [loadingChangedp, setloadingChangedp] = useState(false)
  useEffect(() => {
    props.callback()
  }, [])
  const handlegoingback = () => {
    props.goBack(props.data.type)
  }
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  const handleDeleteProduct = async () => {
    try {
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');

      const docId = props.data.id;

      await deleteDoc(doc(productsCollection, docId));

      if (props.data.image) {
        const mainImageRef = storage.refFromURL(props.data.image);
        await mainImageRef.delete();
      }

      const moreImages = urls || props.data.moreimages;

      if (moreImages && Array.isArray(moreImages)) {
        console.log('inside');
        for (const imageUrl of moreImages) {
          const imageRef = storage.refFromURL(imageUrl);
          try {
            await imageRef.delete();
          } catch (error) {
            console.error(`Error deleting image ${imageUrl}:`, error);
          }
        }
      }


      props.goBack(props.data.type); // Call the goBack function after successful deletion
      // Call any necessary clean-up or state updates after deletion
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  const handleDelete = async (index) => {
    try {
      const imageUrl = urls[index];
      const imageRef = storage.refFromURL(imageUrl);
      await imageRef.delete();
      const copyOfurls = [...urls];
      copyOfurls.splice(index, 1);
      setUrls(copyOfurls);
      setrunUpdate(true);
      setText('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleSelecttwo = (index) => {
    setIsSelectedtwo(!IsSelectedtwo);
    setIsclick((prevIndex) =>
      prevIndex === index ? null : index
    );
  };
  const handleDpChange = async (event) => {
    const file = event.target.files[0];
    setloadingChangedp(true)
    if (!file) return;

    try {
      // Compress the selected image
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1, // Set the maximum allowed file size in MB
        maxWidthOrHeight: 800, // Set the maximum width or height of the image
      });

      // Get the Firestore and Firebase storage references
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');

      const docId = props.data.id;

      // Delete the previous image 
      try {
        const oldImage = newdp || props.data.image
        if (props.data.image) {
          const oldImageRef = storage.refFromURL(oldImage);
          await oldImageRef.delete();
          console.log("Old image deleted successfully");
        }
      } catch (error) {
        console.error("Error deleting the old image:", error);
      }

      // Upload the new image to storage
      const storageRef = storage.ref();
      const newImageRef = storageRef.child(`images/${compressedFile.name}`);
      await newImageRef.put(compressedFile);

      // Get the download URL of the newly uploaded image
      const newImageUrl = await newImageRef.getDownloadURL();

      // Update the Firestore document with the new image URL
      await updateDoc(doc(productsCollection, docId), {
        image: newImageUrl,
      });

      // Update the state with the new image URL
      setnewdp(newImageUrl);
      setrunUpdate(true);

      console.log('Image updated successfully');
    } catch (error) {
      console.error('Error updating image:', error);
    }
  };



  const handleChange = (e) => {
    const newImages = [];
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage["id"] = Math.random();
      newImage["src"] = URL.createObjectURL(newImage); // Add a src property with the temporary URL
      newImages.push(newImage);
    }
    setImages((prevState) => [...prevState, ...newImages]);
  };

  const handleUpload = () => {
    setIsFetching(true);
    const promises = images.map((image) => {
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
      return uploadTask
        .then((snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        })
        .then(() => {
          return storage
            .ref("images")
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              return url;
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });

    Promise.all(promises)
      .then((downloadUrls) => {
        setUrls((prevState) => [...prevState, ...downloadUrls]);
        alert("All images uploaded");
        setrunUpdate(true)
        setImages([])
        setText('changes updated sucessfully')
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (runUpdate) {
      updateDocument();
    }
  }, [urls, runUpdate])
  const updateDocument = async () => {
    try {
      setloadingChangedp(false)
      const firebase = getFirebase();
      const firestore = getFirestore(firebase);
      const productsCollection = collection(firestore, 'products');

      const product = {
        image: newdp,
        title: title,
        price: Price,
        description: description,
        moreimages: urls,
        warranty: sliderValue,
        title_lowercase: title.toLowerCase()
      };

      const docId = props.data.id;

      if (runUpdate) {
        setIsFetching(true)
        await updateDoc(doc(productsCollection, docId), product)
          .then(() => {
            setrunUpdate(false);
            setupdateSuncess(true);
            setIsFetching(false);
          })
          .catch(error => {
            console.error('Error updating product:', error);
          });
      }
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  useEffect(() => {
    const updateInputPropsStyles = () => {
      if (window.innerWidth <= 600) {
        setInputPropsStyles({
          style: {
            width: "80vw",
          },
        });
      } else {
        setInputPropsStyles({});
      }
    };

    updateInputPropsStyles();
    window.addEventListener('resize', updateInputPropsStyles);
    return () => {
      window.removeEventListener('resize', updateInputPropsStyles);
    };
  }, []);

  return (
    <div className={classes.root}>

      <Typography variant="body1" style={{ fontSize: '1.5rem', marginBottom: "3%" }}>
        Type: {props.data.type}
      </Typography>
      <div className={classes.imageContainer}>
        <CardMedia
          component="img"
          alt="Product Image"
          src={newdp}
          style={{
            borderRadius: "5%", width: "70vw"
          }}
        />
      </div>
      <input type="file" accept="image/*" onChange={handleDpChange} style={{ display: 'none' }} id="fileInput" />
      <label htmlFor="fileInput">
        <Button variant="contained" component="span" style={{ marginBottom: "15%", backgroundColor: '#c4b205', color: "white" }}>
          Change dP  {loadingChangedp && <CircularProgress size={15} style={{ color: 'white' }} />}
        </Button>
      </label>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)} // Update the title state on change
        className={classes.datafields}
        variant="outlined"
        InputProps={{
          style: { color: 'white', width: '70vw', marginBottom: '5%', ...inputPropsStyles.style },
        }}
      />
      <TextField
        label="Price"
        value={Price}
        onChange={(e) => setPrice(e.target.value)} // Update the title state on change
        className={classes.datafields}
        variant="outlined"
        InputProps={{
          style: { color: 'white', width: '70vw', marginBottom: '5%', ...inputPropsStyles.style },
        }}
      />
      <TextField
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)} // Update the description state on change
        className={classes.datafields}
        multiline
        minRows={5}
        variant="outlined"
        InputProps={{
          style: { color: 'white', width: '70vw', marginBottom: '5%', ...inputPropsStyles.style },
        }}
      />

      <Slider
        className={classes.slider}
        defaultValue={props.warranty}
        // getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-small-steps"
        step={1}
        marks
        value={sliderValue}
        onChange={handleSliderChange}
        min={0}
        max={15}
        valueLabelDisplay="auto"
      />
      <Typography variant="body1" style={{ marginBottom: "10%" }}>Warranty: {sliderValue} months.</Typography>
      <Typography variant="body1" style={{ fontSize: '1.2rem', marginBottom: "3%", fontWeight: "800" }}>
        Other images
      </Typography>
      <div style={{ paddingBottom: "10%", borderBottom: "1px solid white" }}>
        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {urls.map((url, index) => (
            <Grid item key={index} xs={12} sm={4} md={3}>
              <div style={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  alt="Uploaded Images"
                  src={url}
                  onClick={() => handleSelecttwo(index)}
                  style={{ border: Isclick === index ? '5px solid white' : 'none', borderRadius: "5%" }}
                />
                {Isclick === index && (
                  <IconButton className={classes.deleteButton} aria-label="Delete" color="secondary" onClick={() => handleDelete(index)}>
                    <DeleteIcon style={{ fontSize: "2rem" }} />
                  </IconButton>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <Grid container spacing={2} style={{ marginTop: "5%" }}>
        {images.map((image, index) => (
          <Grid item key={index} xs={12} sm={4} md={3}>
            <CardMedia component="img" alt="Uploaded Image" src={image.src} />

          </Grid>

        ))}
      </Grid>
      <input
        accept="image/*"
        id="upload-button"
        multiple
        type="file"
        style={{ display: 'none' }}
        onChange={handleChange}
      />
      <label htmlFor="upload-button">
        <Button variant="contained" component="span" style={{ marginTop: "15%", backgroundColor: '#c4b205', color: "white" }}>
          Upload More Images
        </Button>
      </label>

      <Snackbar open={updateSuncess} autoHideDuration={6000} onClose={() => setupdateSuncess(false)}>
        <Alert severity="success">
          {text}
        </Alert>
      </Snackbar>
      <Footer isFetching={isFetching} goingback={handlegoingback} Callback={handleUpload} Callbackdelete={handleDeleteProduct} productname={props.data.type} showdelete={true} />
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "20%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }, slider: {
    color: 'yellow',
    width: "90%",
    marginTop: "5%",
  },
  absoluteback: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
    zIndex: '1',
  },
  imageContainer: {
    position: 'relative',
    marginBottom: "2%",
  },
  image: {
    width: '50vw',
    marginBottom: '5%',
  },
  selectedImage: {
    width: '50vw',
    marginBottom: '5%',
    border: '2px solid WHITE',
  },
  cardActions: {
    position: 'relative',

  },
  deleteButton: {
    fontSize: '2rem',
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 1,
  },
  saveButton: {
    fontSize: "1.2rem",
    color: "white",
    backgroundColor: "Transparent",
    position: "fixed",
    right: 0,
    top: "1%",
    zIndex: "9999"
  }, datafields: {
    '& label.Mui-focused': {
      color: '#c4b205',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#c4b205',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#c4b205',
      },
      '& input': {
        color: 'white',
        // Set the text color to white
      },
    },
  },
  '@media(max-width:600px)': {
    image: {
      width: '90vw',
      marginBottom: '5%',
    },
    selectedImage: {
      width: '70vw',
      marginBottom: '5%',
      border: '2px solid WHITE',
    },
    slider: {
      color: 'yellow',
    },
    saveButton: {
      fontSize: "1.2rem",
      color: "white",
      right: theme.spacing(2),
      top: "8%",
      zIndex: 9999,
      backgroundColor: '#c4b205'
    }
  }
}));



export default OpenProducts;
