
import {Tooltip, Fab} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Link,
} from '@material-ui/core';
import { getFirestore, collection, getDocs , query as firestoreQuery, where,} from 'firebase/firestore';
import app from '../../config/fbConfig';
import { CircularProgress } from '@material-ui/core';

function ProjectGrid(props){
    
    const classes = useStyles();
      const [loader,setshowLoader] = useState(false)
      const [ProjectData,setProjectData] = useState([])

      const fetchProducts = async (product) => {
        try {
          const db = getFirestore(app);
          const collectionRef = collection(db, 'products');
      
          // Add a query to filter documents by type
          const productQuery = firestoreQuery(collectionRef, where('type', '==', product));
      
          await getDocs(productQuery)
            .then((snapshot) => {
              const products = snapshot.docs.map((doc) => ({
                id: doc.id, // Include the document ID
                ...doc.data(), // Include the document data
              }));
              setProjectData(products);
              setshowLoader(false)
            })
            .catch((error) => {
              console.log('Error getting documents: ', error);
            });
        } catch (error) {
          console.log('Error getting documents: ', error);
        }
      };
      useEffect(()=>{
        setshowLoader(true)
        fetchProducts('Project')
      },[])
      const handleOpen = (data) =>{
        props.callhandleOpen(data)
      }
return(
    <div>
        {loader && <div className={classes.loader}>
            <CircularProgress style={{color:"grey"}}/>
        </div>
        }
         <Container>
            <Grid container spacing={2}>
                {ProjectData.map((card) => (
                <Grid item xs={12} md={4} key={card.id}>
                    <Card className={classes.card}>
                    <CardMedia
                        className={classes.cardMedia}
                        image={card.image}
                        title={card.title}
                    />
                    <CardContent className={classes.cardContent}>
                        <Typography variant="h6" className={classes.title}>{card.title} </Typography>
                            <div className={`${classes.btn} ${classes.btnThree}`} onClick={() => handleOpen(card)}>
                                <span>MORE</span>
                            </div>
                    </CardContent>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Container>
        <Tooltip title="Add" aria-label="add">
                <Fab className={classes.absolute} onClick={props.btnclicked}>
                    <AddIcon />
                </Fab>
            </Tooltip>
    </div>

)}
const useStyles = makeStyles((theme) => ({
    loader:{
        height:"100vh",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    title: {
      letterSpacing:"2px",
      paddingBottom:"5px"
    },
    card: {
        display: 'flex',
        background:"#505050",
        flexDirection: 'column',
        marginBottom:"5%",
        [theme.breakpoints.up('md')]: {
          flexDirection: 'column',
          margin:"5%"
        },
      },
      cardContent: {
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
        color:"white",
      },
      cardMedia: {
        width: '100%',
        height: '200px',
        transition: 'transform 0.3s ease-in-out', // Adding a smooth transition for the zoom effect
        '&:hover': {
            transform: 'scale(1.1)', // Increasing the scale on hover (zoom effect)
        },
      },
    fab: {
        
    },
    absolute: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(3)
    },
    btn: {
        lineHeight: '50px',
        height: '50px',
        textAlign: 'center',
        width: '250px',
        cursor: 'pointer',
        letterSpacing:"3px"
      },
      btnThree: {
        color: '#FFF',
        transition: 'all 0.5s',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          transition: 'all 0.3s',
        },
        '&:hover::before': {
          opacity: 0,
          transform: 'scale(0.5, 0.5)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          transition: 'all 0.3s',
          border: '1px solid rgba(255, 255, 255, 0.5)',
          transform: 'scale(1.2, 1.2)',
        },
        '&:hover::after': {
          opacity: 1,
          transform: 'scale(1, 1)',
        },
      },
    
}));
export default ProjectGrid;