import React from 'react'
import { Grid, Box } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia, Card } from "@material-ui/core";
import { useSpring, animated, config } from 'react-spring';
import Button from "@material-ui/core/Button";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import VisibilityOutlined from '@material-ui/icons/VisibilityOutlined'
import menuItems from '../../constants/menuItems';
import SocialMediaBar from "../../components/SocialMediaBar/SocialMediaBar";


const PaginationElement = props => {
    const classes = useStyles();
    const issmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const animProps = useSpring({
        config: config.stiff, // You can adjust the animation config as needed
        opacity: 1,
        transform: 'translate(0%, 0%)',
        from: { opacity: 1, transform: 'translate(0px, 0%)' }, // Change the initial transform value
    });
    const [type, settype] = React.useState()
    const findType = (searchElement) => {
        if (menuItems.products.indoorProducts.includes(searchElement)) {
            settype('Indoor')
        } else if ([...menuItems.products.outdoorProducts, ...menuItems.products.MoreOutdoor].includes(searchElement)) {
            settype('Outdoor')
        } else {
            settype('Project')
        }
    }
    React.useEffect(() => {
        findType(props.type);
    }, [props.type]);
    const handleView = () => {
        props.callback(props.type)
    }
    return (
        <Grid container className={props.styles}>
            {!issmall && <div className={`${classes.information} ${classes.blackSheet}`}>
                <Box className={classes.socialMedia}>
                    <SocialMediaBar />
                </Box>
            </div>}
            <div
                item
                xs='auto'
                sm='auto'
                md={1}
                className={`${classes.left} ${issmall ? classes.blackSheet : ''}`}

                style={issmall ? {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: 'translate(-50%, -50%)'
                } : {}}
            >
                <animated.div style={animProps} className={classes.titleDiv}>
                    <Typography component='div' className={classes.title}>
                        <Typography xs={{ fontSize: 30, }} variant='h2'>
                            <Box style={{ fontFamily: 'Tahoma  ' }}>
                                {props.title}
                            </Box>
                        </Typography>
                        <Typography variant='subtitle1'>
                            <Box lineHeight='4' style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif' }}>
                                {type}
                            </Box>
                        </Typography>
                        <Typography component='div' variant='body1' className={classes.body}
                        >
                            <Box lineHeight='1.5' width={300} fontWeight="fontWeightRegular" >
                                {props.description}
                            </Box>
                        </Typography>
                        <div style={{
                            display: "flex", flexDirection: "row", justifyContent: issmall ? "center" : "left"
                        }}>
                            <Button className={classes.button} onClick={handleView}>View <VisibilityOutlined /></Button>
                        </div>
                    </Typography>
                </animated.div>
            </div>
            <Grid item xs={12} sm={12} md={12} className={classes.right}>
                <Card style={{ height: '100%' }}>
                    <CardMedia image={props.image} className={classes.img} />
                </Card>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    blackSheet: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.4)', // Adjust the opacity (0.5) as needed
        zIndex: 1, // Ensure it's above the carousel content
    },
    titleDiv: {
        top: '20vh',
        position: 'absolute',
        zIndex: "2",
        [theme.breakpoints.down('sm')]: {
            left: '50%',
            marginLeft: -150,
        }
    },
    title: {
        backgroundColor: 'transparent',
        padding: theme.spacing(5),
        borderRadius: "3%",
        fontFamily: "Helvetica",
        fontWeight: "bold",
        textShadow: theme.palette.surface + ' 1px 1px 1px',
        color: theme.palette.onBackground,
        width: 500,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            paddingTop: "5%",
            textAlign: 'center',
            width: 300,
        }
    },
    socialMedia: {
        position: 'absolute',
        backdropFilter: "blur(5px)",
        bottom: '4%',
        left: '2%',
        color: 'white',
    },
    body: {
        textShadow: 'white 0px 0px 0px',
        marginTop: 15,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            textShadow: theme.palette.surface + ' 1px 1px 1px',
            textAlign: 'center',
            width: '400'
        },
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': 5, // Number of lines to display
        '-webkit-box-orient': 'vertical',

    },
    left: {
        height: '100%',
        position: 'absolute',
        bottom: '4%',
        left: '10%',
    },
    right: {
        height: '100%'
    },
    img: {
        height: '100%',
        width: '100%',
    },
    button: {
        marginTop: '8vh',
        color: theme.palette.ter,
    },

}));

export default PaginationElement
