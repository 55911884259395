import React from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles } from "@material-ui/core/styles";
import { Box } from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import SideButton from "./SideButton/SideButton";
import SideMenuItem from "./SideMenuItem/SideMenuItem"
import CarPark from '../../../resources/icons/carpark2.jpg'
import SailShade from '../../../resources/icons/sailshade.jpg'
import Awning from '../../../resources/icons/awning.jpg'
import Retractable from '../../../resources/icons/retractable.jpg'
import Fixed from '../../../resources/icons/fixed.jpg'
import Grass from '../../../resources/icons/grass.jpg'
import IF from '../../../resources/icons/IF.jpg'
import POOLBED from '../../../resources/icons/poolbed.jpg'
import swings from '../../../resources/icons/swings.jpg'
import heaters from '../../../resources/icons/heaters.jpg'
import sofas from '../../../resources/icons/sofas.jpg'
import dining from '../../../resources/icons/dining.jpg'
import wpc from '../../../resources/icons/wpc.jpg'



const Outdoor = () => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        console.log('handle click')
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        console.log('handleclose')
        setAnchorEl(null);
    };

    return (
        <Box style={{ background: 'red', overflowY: 'hidden' }}>
            <SideButton
                variant={(anchorEl === null) ? 'contained' : 'outlined'}
                onclick={handleClick}
                text='Outdoors'
            >
                <WbSunnyIcon className={classes.icon} />
            </SideButton>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "center", horizontal: -200 }}
                transformOrigin={{ vertical: "center", horizontal: "center" }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
            >
                <Box display='flex' flexDirection='column' component='div' className={classes.menu} onMouseLeave={handleClose}>
                    <Box display='flex' flexDirection='row' component='div'>
                        <SideMenuItem text='Car Parks' image={CarPark} className={classes.btns} />
                        <SideMenuItem text='Fixed Structures' image={Fixed} className={classes.btns}>
                        </SideMenuItem>
                    </Box>
                    <Box display='flex' flexDirection='row' component='div'>
                        <SideMenuItem text='Retractable Structures' className={classes.btns} image={Retractable} />
                        <SideMenuItem text='Awnings' image={Awning} className={classes.btns} />
                    </Box>
                    <Box display='flex' flexDirection='row'>
                        <SideMenuItem text='Artificial Grass' image={Grass} style={{
                            border: "none"
                        }} className={classes.btns} />
                        <SideMenuItem text='Sail Shades' image={SailShade} className={classes.btns} />
                    </Box>
                    <Box display='flex' flexDirection='row' style={{ textAlign: "center" }}>
                        <SideMenuItem text='Swings' image={swings} className={classes.btns} />
                        <SideMenuItem text='Sofas' image={sofas} className={classes.btns} />
                    </Box>

                    <Box display='flex' flexDirection='row'>
                        <SideMenuItem text='Dining' image={dining} style={{
                            border: "none"
                        }} className={classes.btns} />
                        <SideMenuItem text='Pool Beds' image={POOLBED} className={classes.btns} />
                    </Box>
                    <Box display='flex' flexDirection='row' style={{ textAlign: "center" }} className={classes.btns}>
                        <SideMenuItem text='Wood Plastic Composite' image={wpc} className={classes.btns} />
                        <SideMenuItem text='Outdoor Heaters' image={heaters} />
                    </Box>


                </Box>
            </Menu>
        </Box>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: 'white',
        fontSize: 40
    },
    items: {
    },
    fullwidth: {
        textAlign: 'center',
    },
    btns: {
        border: "1px solid white"
    }
}));

export default Outdoor;
