import React from 'react'
import { Grid, Box } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {CardMedia, Card} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';


const Carousel = props => {
    const classes = useStyles();
    const issmall = useMediaQuery(theme => theme.breakpoints.down('sm'));  
    return(
        <Grid container  className={props.styles} spacing={3}>
            <Grid item xs={12} sm={12} md={12} className={classes.right}>
                    <Card style={{height: '100%'}}>
                            <CardMedia image={props.image} className={classes.img}/>
                    </Card>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    // titleDiv:{
    //     top: '20vh',
    //     position: 'absolute',
    //     [theme.breakpoints.down('sm')]: {
    //         left: '50%',
    //     }
    // },

    right:{
        height: '100%',
    },
    img: {
        height: '100%',
        width: '100%',
        
    },

}));

export default Carousel;
