import React, { useEffect, useState } from 'react';
import './hrscroll.css'
import { animated, useSpring } from "react-spring";
import { useScroll } from "react-use-gesture";
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';



const ScrollingHor = (props) => {
  const ismobile = useMediaQuery('(max-width: 768px)');
  const repeatComponent = new Array(5).fill(null);
  const history = useHistory();
  useEffect(() => {

  }, props.data)
  const [style, set] = useSpring(() => ({
    transform: "perspective(500px) rotateY(0deg)"
  }));
  const isSmallScreen = window.innerWidth < 1000; // Adjust the threshold as needed
  const bind = useScroll((event) => {
    if (isSmallScreen) {
      set({
        transform: `perspective(500px) rotateY(${event.scrolling ? event.delta[0] * 0.3 : 0
          }deg)`,
      });
    } else {
      set({
        transform: `perspective(500px) rotateY(${event.scrolling ? event.delta[0] : 0
          }deg)`,
      });
    }
  });
  const handleProjectClick = (index) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: `/project/${index.title.replace(/\s/g, '-').toLowerCase()}`,
      state: { data: index }
    });
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <div className='recent-containere' >
        <ArrowForwardIcon style={{ fontSize: 40, color: 'white' }} />
        <Typography className='recente' style={{ color: "white" }} variant='h4'>
          {props.title}
        </Typography>
      </div>
      <div >
        <Typography
          className='subheadere'
          style={{ color: 'white', background: "#c4b205", marginLeft: "10%", marginTop: "2%", paddingLeft: "10px", paddingRight: "10px", display: 'inline-block' }}
          variant='h6'
        >
          {props.subheader}
        </Typography>
        <Divider style={{ background: '#c4b205', marginTop: '-15px', marginRight: "5%", marginLeft: "5%" }} />
      </div>
      <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
        <div className="containere" {...bind()}>
          {props.data ? (
            props.data.map((index, i) => (
              <div key={index.image} style={{ marginTop: ismobile ? "10px" : `${i % 2 === 0 ? 20 : 0}px` }} onClick={() => handleProjectClick(index)}>
                <animated.div
                  className="carde"
                  style={{
                    ...style,
                    backgroundImage: `url(${index.image})`,
                    cursor: "pointer",
                  }}
                />
                <h3 style={{
                  textAlign: "center", color: "white", fontFamily: ismobile ? "arial" : "Helvetica",
                  textTransform: 'capitalize', fontWeight: "bold"
                }}>{index.title}</h3>
              </div>
            ))
          ) : (

            repeatComponent.map((_, index) => (
              <div style={{ marginTop: ismobile ? "10px" : `${index % 2 === 0 ? 0 : 20}px` }}>
                <div key={index}  {...bind()}>
                  <animated.div
                    className="carde"
                    style={{
                      ...style,
                      backgroundImage: null,
                      cursor: "pointer",
                    }}
                  >

                    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#c4b205" >
                      <Skeleton height={ismobile ? 200 : 360} style={{ marginTop: ismobile ? "10px" : `${_ % 2 === 0 ? 0 : 20}px` }} />
                      <div style={{ textAlign: 'center' }}>
                        <Skeleton height={20} width={'50%'} style={{ marginTop: '20px', textAlign: 'center' }} />
                      </div>
                    </SkeletonTheme>
                  </animated.div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};
export default ScrollingHor;
