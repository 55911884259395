const initialState = {};

const exploreProductsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRODUCTS":
            const { type, products } = action.payload;
            return {
                ...state,
                [type]: products
            };
        default:
            return state;
    }
};

export default exploreProductsReducer;
