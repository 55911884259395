import React from "react";
import Outdoor from "./SideBarElements/Outdoor";
import {Box} from '@material-ui/core';
import Indoor from "./SideBarElements/Indoor";
import Projects from "./SideBarElements/Projects";
import ContactUs from "./SideBarElements/ContactUs";
import About from "./SideBarElements/About";
import logo from '../../resources/icons/logoenhanced-removebg.png'
import {useSpring, animated} from "react-spring";
import {makeStyles} from "@material-ui/core/styles";

const SideBar = () => {
    const classes = useStyles()

    const animProps = useSpring(
        {
            config:{
                tension: 110, friction: 14, mass: 1.5
            },
            opacity: 1,
            transform: "translate(0%, 0%)",
            from: { opacity: 1, transform: "translate(0px, 20px)" }
        })

    return(
        <animated.div style={animProps}>
            <Box sx={{
        height: 125,}}>
                    <img src={logo} alt='' style={{
                        width:'100%',
                        height: '100%',
                    }}/>
                <Outdoor/>
                <Indoor/>
                <Projects/>
                <About/>
                <ContactUs/>
            </Box>
        </animated.div>
    )
}

const useStyles = makeStyles((theme) => ({
    sideBar: {
        height: "auto", // Default height
        "@media (max-width: 1000px)": {
          height: "130px", // Adjust the height for max-width: 1000px
        },
        "@media (max-width: 1200px)": {
          height: "150px", // Adjust the height for max-width: 800px
        },
        "@media (max-width: 1500px)": {
          height: "170px", // Adjust the height for max-width: 600px
        },
      },
    }));

export default SideBar
