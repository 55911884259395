const DashboardData = {
  title: "Welcome to V&S ADMIN",
  content: "Welcome, Admin! Seamlessly oversee our diverse furniture range and elevate spaces with timeless pieces. Let's craft extraordinary interiors and create a lasting impression."
};

const activities = [
  { description: 'Order #2912 has been successfully placed.', daysAgo: '58 min' },
  { description: 'Your weekly report is ready to view.', daysAgo: '2 hrs' },
  { description: 'New user Valerie Luna has registered', daysAgo: '1 day' },
  { description: 'Server activity monitor alert', daysAgo: '1 day' },
  { description: 'Order #2911 has been successfully placed.', daysAgo: '1 day' },
  { description: 'Details for Marketing and Planning Meeting have been updated.', daysAgo: '2 days' },
  { description: 'Order #2910 has been successfully placed.', daysAgo: '2 days' },
];

export { DashboardData, activities };

