
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { setAdminActive } from '../../store/actions/authenticateadmin';
import React, { useState } from 'react';
import { auth } from '../../config/fbConfig';
import Image from '../../resources/icons/logoenhanced.jpg';
import { CircularProgress } from '@material-ui/core';

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#c4b205',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: '#c4b205',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#c4b205',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'none', // Hide the image by default
        [theme.breakpoints.up('sm')]: {
            display: 'block', // Show the image on screens larger than or equal to sm breakpoint (600px)
        },
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: "5%",
        border: "2px solid #c4b205",
        borderRadius: "2%"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.pri,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: theme.palette.sec,
        '&:hover': {
            backgroundColor: theme.palette.pri,
        },
    },
    title: {
        color: theme.palette.pri
    },
    inputText: {
        color: 'white'
    },
    parentdiv: {
        height: "100%"
        , display: "flex"
        , alignItems: "center"
    }
    ,
    '@media (max-width: 900px)': {

    },
}));

export default function Login() {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, seterror] = useState('')
    const history = useHistory()
    const dispatch = useDispatch();
    const [loader, setloader] = useState(false)

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setloader(true)
        // Retrieve the authentication object
        try {
            // Sign in the user with email and password
            await signInWithEmailAndPassword(auth, username, password)
                .then((response) => {
                    setloader(false)
                    if (response._tokenResponse.registered) {
                        dispatch(setAdminActive(true));
                        localStorage.setItem('idToken', response._tokenResponse.idToken);
                        history.push('/admin')
                        window.scrollTo(0, 0);
                        console.log(response)
                    }
                }

                ) // User signed in successfully
            // Additional actions (e.g., redirecting to a new page)
        } catch (error) {
            setloader(false)
            // Handle errors during sign in
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Sign-in error:", errorCode, errorMessage);
            seterror("invalid creditentials")
        }
    };


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
                <div className={classes.parentdiv}>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            Sign in to VNS Admin
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <CssTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus

                                value={username}
                                onChange={handleUsernameChange}
                                InputProps={{
                                    className: classes.inputText
                                }}
                            />
                            <CssTextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                autoComplete="current-password"
                                InputProps={{
                                    className: classes.inputText
                                }}
                            />
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Link href="#" variant="body2" className={classes.title}>
                                Forgot password?
                            </Link>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                            {loader && <div style={{ textAlign: "center" }}><CircularProgress style={{ color: "#c4b205" }} /></div>}
                            <div style={{ color: "red", paddingTop: "5%" }}>{error}</div>

                        </form>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}
