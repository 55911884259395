import aboutUsImage from '../img/awning1.jpg'



const content = {
    aboutScreen: {
        title: 'About Us',
        titleBody: 'We are pleased to introduce ourselves as the one of the company in Pakistan ' +
            'which deals with both interior furnishing and exterior solar protection under one umbrella.' +
            ' “Vectors N Scalars” is committed to highest level of design with a "turn-key" approach to ' +
            'our services offered.',
        titleImage: aboutUsImage,
        features: [
            {
                title: 'Wide Range',
                body: 'With so many choices you want to be sure you trust your interior ' +
                    'designer to give you the best advice, service and price. We provide a wide variety of top quality indoor and' +
                    'outdoor products.'
            },
            {
                title: 'Design Principles',
                body: 'Our design principles are based on honesty and practicality, where a variety of styles can ' +
                    'cater to our clients’ individual tastes and preferences.'
            },
            {
                title: '3D Designing',
                body: 'For the ease of our customers we offer full assistance in 3D interior designing and its proper execution'
            },
            {
                title: 'Finest Material',
                body: 'Using the finest material and superior workmanship, we create work and living places that enhance' +
                    'the quality of life. we utilize materials, colors and lights in ways ' +
                    'that are both aesthetically pleasing and relaxing'
            },
        ]
    },
    footer: {
        companyDescription: 'One of the few companies in Pakistan that deals with both interior furnishing and ' +
            'exterior solar protection all under one umbrella.',
        address: 'Plaza 100, G-1, Walayat Complex, Phase 7, Bahria Town, Islamabad',
        phone: '+92 331 2729444',
        companyEmail: 'Uzma.hafeez912@gmail.com'
    },
    OpenedProject: {
        data: `Our goal is to provide you with top-notch quality and service that exceeds your expectations. Your trust and satisfaction mean the world to us.
        At Vectors & Scalars, we believe in building lasting relationships with our valued clients. We take immense pride in the quality of our work and the trust that you've placed in us.
         As you move forward, we want you to know that we're here to support you every step of the way.We are excited about the journey ahead and are committed to continually delivering excellence.
         Thank you for choosing Vectors & Scalars. Your satisfaction is our ultimate reward. Let's continue to turn your ideas into reality and create impactful solutions that drive success. Your success is our success,
          and we can't wait to see what incredible projects we'll accomplish together.`
    }
}

const warranty = [
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },
    {
        text: '1 year',
        value: 1
    },


]


export default content
