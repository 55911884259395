import React from 'react';
import './openproduct.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CircularProgress } from '@material-ui/core';
import Carousel from '../OpenProjects/carousel'; // Import your PaginationElement component
import {makeStyles} from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import Header from '../../../Header';
import { Grid, Paper, Typography,Icon } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LocationOnIcon from '@material-ui/icons/LocationOn'; // Import the location icon
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'; // Import the description icon
import Footer from '../../Footer';
import Divider from '@material-ui/core/Divider';
import { ArrowDownward } from '@material-ui/icons';
import content from '../../../../constants/content';
const OpenProjects = () => {
const issmall = useMediaQuery(theme => theme.breakpoints.down('sm'));  
  const settings = {
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow : 3,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
  };
  
  const location = useLocation();
  const data = location.state?.data;
  const classes = useStyles()
  
  if (window.innerWidth <= 768) {
    settings.slidesToShow = 2;
  } else {
    settings.slidesToShow = 3;
  }
  return (
    <div> 
        <Header/>
        <div className={classes.body}>
        <Grid container spacing={2}>
            {/* Left side: Image and Title */}
            <Grid item xs={12} md={7}>
            <div class="six" style={{paddingBottom:"2%"}}>
                <div className='heading' >{data.title}
                    <span>Where Vision Meets Reality</span>
                </div>
                </div>
            <Paper elevation={3} >
                <img
                src={data.image}
                alt="Project"
                style={{ width: '100%', height: 'auto' }}
                />
            </Paper>
            </Grid>

            {/* Right side: Description */}
            <Grid item xs={12} md={5} style={{paddingTop:issmall?"0%":"10%"}}>
            <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="body1" style={{display:"flex",flexDirection:"column",paddingBottom:"1%"}}>
                    <div style={{textAlign:"right"}}><Icon component={LocationOnIcon} style={{ verticalAlign: 'middle', marginRight: '5px' }} /></div>
 
                <div>{data.location}</div>
                </Typography>
            </Paper>
            <Paper elevation={3} style={{ padding: '20px' }}>
              
                <Typography variant="body1" style={{display:"flex",flexDirection:"column",paddingBottom:"3%",height:"100%",position :"relative"}} >
                    <div style={{textAlign:"right",}}><Icon component={ChatBubbleOutlineIcon} style={{ verticalAlign: 'middle', marginRight: '5px' }} /></div>
 
                <div className={classes.description}>
                {data.description}<div style={{position:"absolute",bottom:-15,right:0}}><ArrowDownward/></div></div>
                </Typography>
            </Paper>
          
            </Grid>
        </Grid>
            <Divider style={{background:"white",color:"white",marginTop:"5%"}}/>
        </div>
            {!issmall && <div variant="h4" style={{marginTop:"7%",marginLeft:"7%" ,color:"white"}} className='ourwork'>
                    Our Works
            </div>}
        <div className={classes.crouselContainer} style={{
        marginTop:issmall?"5%":"0%"}}>
            {issmall && <div className='ourwork' style={{color:"white",paddingTop:"5%",position:"absolute",top:-15,zIndex:"1"}} >
                Our work
            </div>}
            <Slider {...settings}>
                {data.moreimages.map((image, index) => (
                <div key={index}>
                    <Carousel
                    styles={classes.carousel}
                    image={image}
                    />
                </div>
                ))}
            </Slider>
        </div>
        <div style={{border:"1px solid white",margin:"5%"}}>
            <Typography variant='body1' style={{color:"white",fontFamily:"Roboto",fontSize:20,padding:"5%"}}>
                {content.OpenedProject.data}
            </Typography>
        </div>
        <div style={{marginTop:"10%"}}>
            <Footer />
        </div>
    </div>
  );
      }
const useStyles = makeStyles((theme) => ({
    body:{
        marginLeft:"10%",
        marginRight:"10%",
        marginTop:"10%",
        [theme.breakpoints.down('xs')]: {
        marginTop:"23%",
        },

    },
    title:{
        padding: '15px',
        paddingLeft:"0"
        ,color:"white",
        textTransform: 'capitalize' 
    },
    description:{
        display: '-webkit-box',
        '-webkit-line-clamp': 14,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxHeight: '300px', // Set a maximum height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        
    },
    socialMedia: {
        position: 'absolute',
        bottom: '4%',
        left: '2%',
        color: 'white',
    },
    pagination: {
        background:"transparent",
        marginTop: '2vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0vh',
            marginBottom: '0vh'
        }
    },
    carousel: {
        height:'60vh',
        marginTop:"10%",
        marginBottom:"10%",
        backgroundColor:"#282424",
        [theme.breakpoints.down('sm')]: {
            marginTop:"20%",
            height: '30vh',

        },
        position: 'relative',
        overflow: 'hidden',background:"transparent",
    },
    sideBar: {
    },
    crouselContainer:{
        position:"relative",
        borderLeft:"10px solid #282424",
        background:"#282424",
        marginLeft:"7%",
        marginRight:"7%",
    }
}));

export default OpenProjects;
