import React from 'react';
import { Grid, makeStyles, Card, CardContent, CardMedia, Typography, Divider } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    margin: '5%',
  },
  card: {
    background: "#545454",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    cursor: "pointer",
  },
  cardContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  media: {
    paddingTop: '60%',
    backgroundSize: 'cover',
  },
  tags: {
    display: 'inline-flex',
    padding: '5px',
    borderRadius: '10%',
    color: 'white',
    position: 'relative',
    overflow: 'hidden',
    background: 'grey',
  },
  allprojects: {
    marginBottom: "5%",
    color: "white",
    textAlign: "center",
  },
  locationContainer: {
    display: 'flex',
    alignItems: 'center',
    background: 'grey',
    padding: '5px',
    borderRadius: '10%',
    color: 'white',
    position: 'relative',
    overflow: 'hidden',
    borderTop: "3px solid white",
    backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.5), transparent)',
  },
  locationIcon: {
    marginRight: '5px',
  },
  locationText: {
    display: 'inline-flex',
    fontWeight: "bold",
    position: "relative",
  },
  title: {
    color: "white",
    fontWeight: "bold",
    marginTop: "2%",
    fontFamily: "arial",
    textTransform: 'capitalize',
  },
}));

function ShowProjectsGrid(Props) {
  const classes = useStyles();
  const history = useHistory();

  const handleProjectClick = (index) => {
    window.scrollTo(0, 0);
    history.push({
      pathname: `/project/${index.title.replace(/\s/g, '-').toLowerCase()}`,
      state: { data: index }
    });
    window.scrollTo(0, 0);
  };

  const isLoading = !Props.data; // Adjust this as needed based on your loading state

  return (
    <div>
      <div className='recent-containere'>
        <ArrowForwardIcon style={{ fontSize: 40, color: 'white' }} />
        <Typography className='recente' style={{ color: "white" }} variant='h4'>
          All Projects
        </Typography>
      </div>
      <div>
        <Typography
          className='subheadere'
          style={{ color: 'white', background: "#c4b205", marginLeft: "10%", marginTop: "2%", paddingLeft: "10px", paddingRight: "10px", display: 'inline-block' }}
          variant='h6'
        >
          Collaborative Ventures
        </Typography>
        <Divider style={{ background: '#c4b205', marginTop: '-15px', marginRight: "5%", marginLeft: "5%" }} />
      </div>
      <div className={classes.root}>
        <Grid container spacing={3}>
          {isLoading ? (
            // Skeleton loader while data is loading
            Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card className={classes.card}>
                  <SkeletonTheme baseColor="#e0e0e0" highlightColor="#c4b205">
                    <Skeleton className={classes.media} />
                  </SkeletonTheme>
                  <CardContent className={classes.cardContent}>
                    <Skeleton width="60%" height={24} />
                    <Skeleton width="40%" height={20} />
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            // Actual data rendering
            Props.data && Props.data.map((data, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image={data.image}
                    title="Image Title"
                    onClick={() => handleProjectClick(data)}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography key={index} variant="h6" className={classes.title}>
                      {data.title}
                    </Typography>
                    <div className={classes.locationContainer}>
                      <LocationOnIcon className={classes.locationIcon} />
                      <Typography key={index} variant="caption" className={classes.locationText}>
                        {data.location}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )};
        </Grid>
      </div>
    </div>
  );
}

export default ShowProjectsGrid;
