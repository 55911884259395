import React, { useState, useEffect } from "react";
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Typography } from "@material-ui/core";
import jsPDF from "jspdf";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/AddCircleOutlineSharp';
import DeleteIcon from '@material-ui/icons/Delete';
import "jspdf-autotable";
import { InvoiceConstants } from "../../constants/invoiceConstants";
import { toWords } from 'number-to-words';
import { formatText } from "../../constants/invoiceConstants";
import { object } from "prop-types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const InvoiceMaker = ({ InvoiceType }) => {
    const classes = useStyles();
    console.log(InvoiceType ? InvoiceConstants.BelowHeader2.Reference : InvoiceConstants.BelowHeader.Reference)
    const issmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [formData, setFormData] = useState({
        reference: InvoiceType ? InvoiceConstants.BelowHeader2.Reference : InvoiceConstants.BelowHeader.Reference,
        date: new Date().toISOString().substring(0, 10),
        gst: InvoiceType ? InvoiceConstants.BelowHeader2.GST : InvoiceConstants.BelowHeader.GST,
        ntn: InvoiceType ? InvoiceConstants.BelowHeader2.NT : InvoiceConstants.BelowHeader.NT,
        clientName: "",
        clientCity: "",
        clientPhone: "",
        items: [{ description: "", area: "", rate: "", total: "" }],
        totalAmount: 0,
        advancePayment: 0,
        amountInWords: "",
        InvoiceOrQuotation: "Quotation",
        notes: InvoiceType ? InvoiceConstants.Note2 : InvoiceConstants.Note,
    });
    const [areaKey, setAreaKey] = useState(null);
    const [rateKey, setRateKey] = useState(null);

    const TableRowComponent = InvoiceType ? StyledTableRow2 : StyledTableRow;

    useEffect(() => {
        const totalAmount = formData.items.reduce((acc, item) => acc + parseFloat(item.total || 0), 0);
        const advancePayment = formData.advancePayment || totalAmount * 0.8; // 80% advance payment if not manually edited

        setFormData(prevData => ({
            ...prevData,
            totalAmount: totalAmount?.toFixed(2),
            amountInWords: capitalizeWords(toWords(totalAmount))
        }));
    }, [formData.items, formData.advancePayment]);

    const handleInputChange = (index, name, value) => {
        // Create a new array with the updated item
        const updatedItems = formData.items.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    [name]: value
                };
            }
            return item;
        });

        // Check if the name includes 'area'
        if (name.toLowerCase().includes('area')) {
            const area = parseFloat(updatedItems[index][name] || 0); // Dynamically access area using 'name'
            if (!isNaN(area)) {
                setAreaKey(name); // Update the state with areaKey
                console.log('Area:', area);
            } else {
                alert('Please enter a numeric value in area');
            }
        }

        // Check if the name includes 'rate'
        if (name.toLowerCase().includes('rate')) {
            const rate = parseFloat(updatedItems[index][name] || 0); // Dynamically access rate using 'name'
            if (!isNaN(rate)) {
                setRateKey(name); // Update the state with rateKey
                console.log('Rate:', rate);
            } else {
                alert('Please enter a numeric value in rate');
            }
        }

        // Perform the multiplication if both keys exist and are valid numbers
        if (areaKey && rateKey) {
            const area = parseFloat(updatedItems[index][areaKey] || 0);
            const rate = parseFloat(updatedItems[index][rateKey] || 0);

            if (!isNaN(area) && !isNaN(rate)) {
                updatedItems[index].total = (area * rate).toFixed(2); // Calculate total
                console.log('Total:', updatedItems[index].total);
            } else {
                console.log('Invalid data:', updatedItems[index][areaKey], updatedItems[index][rateKey]);
            }
        } else {
            console.log('Area or Rate key is missing.');
        }

        // Update the state with the new items array
        setFormData({ ...formData, items: updatedItems });
    };

    const handleAddRow = () => {
        // Get keys from the first item in the formData.items array
        const keys = Object.keys(formData.items[0]);

        // Create a new row with empty values for each key
        const newRow = keys.reduce((row, key) => {
            row[key] = ''; // Set empty value or some default value
            return row;
        }, {});

        // Add the new row to the formData.items array
        setFormData({ ...formData, items: [...formData.items, newRow] });
    };

    const capitalizeWords = text => text
        .toLowerCase() // Convert the entire string to lowercase
        .split(' ') // Split the string into an array of words
        .map(item => item.charAt(0).toUpperCase() + item.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the array back into a single string

    const handleRemoveRow = (index) => {
        const items = [...formData.items];
        items.splice(index, 1);
        setFormData({ ...formData, items });
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const margin = 10;

        // Function to add header
        const backgroundImage = "pdf-background.png"; // Path to your background image
        const addBackgroundImage = () => {
            const imgWidth = pageWidth;
            const imgHeight = pageHeight / 2; // Half the height of the page
            const imgX = 0; // Center horizontally
            const imgY = (pageHeight - imgHeight) / 2; // Center vertically

            doc.addImage(backgroundImage, 'PNG', imgX, imgY, imgWidth, imgHeight);
        };
        const addHeader = () => {
            // !InvoiceType && addBackgroundImage()
            doc.setFontSize(8);
            doc.addImage(InvoiceType === 'Easel' ? "easel.png" : "InvoiceLogo.png", "PNG", margin, 0, 50, 35);

            const headerTextY = 10;
            const headerTextX = pageWidth - (doc.getTextWidth(InvoiceConstants.HeaderIcons.location.text.split('\n')[0]) + 20);

            doc.text(`${InvoiceType ? InvoiceConstants.HeaderIcons2.phone.text : InvoiceConstants.HeaderIcons.phone.text}`, headerTextX, headerTextY);
            doc.addImage(InvoiceType ? InvoiceConstants.HeaderIcons2.phone.src : InvoiceConstants.HeaderIcons.phone.src, "PNG", headerTextX + (doc.getTextWidth(InvoiceConstants.HeaderIcons.location.text.split('\n')[0]) + 5), headerTextY - 2, 5, 5);
            doc.text(`${InvoiceType ? InvoiceConstants.HeaderIcons2.location.text : InvoiceConstants.HeaderIcons.location.text}`, headerTextX, headerTextY + 10);
            doc.addImage(InvoiceType ? InvoiceConstants.HeaderIcons2.location.src : InvoiceConstants.HeaderIcons.location.src, "PNG", headerTextX + (doc.getTextWidth(InvoiceConstants.HeaderIcons.location.text.split('\n')[0]) + 5), headerTextY + 8, 7, 7);
            doc.text(`${InvoiceType ? InvoiceConstants.HeaderIcons2.globe.text : InvoiceConstants.HeaderIcons.globe.text}`, headerTextX, headerTextY + 20);
            doc.addImage(InvoiceType ? InvoiceConstants.HeaderIcons2.globe.src : InvoiceConstants.HeaderIcons.globe.src, "PNG", headerTextX + (doc.getTextWidth(InvoiceConstants.HeaderIcons.location.text.split('\n')[0]) + 5), headerTextY + 18, 5, 5);
        };

        let yOffset = 0; // Initial y offset from top

        // Add Header
        addHeader();
        yOffset += 35; // Move yOffset down after adding the header

        // Format reference, date, GST, NTN
        doc.setFontSize(10);
        const leftColumnX = margin;
        const rightColumnX = pageWidth - (doc.getTextWidth(`GST#: ${formData.gst}`) + margin);

        if (yOffset + 30 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }
        yOffset += 20;
        doc.text(`Reference: ${formData.reference}`, leftColumnX, yOffset);
        if (formData.gst) doc.text(`GST#: ${formData.gst}`, rightColumnX, yOffset);
        yOffset += 5;
        doc.text(`Date: ${formData.date}`, leftColumnX, yOffset);
        if (formData.ntn) doc.text(`NTN#: ${formData.ntn}`, rightColumnX, yOffset);

        // First Table: Client Details
        doc.setFontSize(10);
        const clientDetails = [
            ["Name:", formData.clientName],
            ["City:", formData.clientCity],
            ["Phone:", formData.clientPhone],
        ];

        if (yOffset + 30 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }

        doc.autoTable({
            head: [["", ""]],
            body: clientDetails,
            startY: yOffset += 20,
            headStyles: {
                display: 'none'
            },
            tableWidth: 'auto',
            margin: { top: 10, left: 10, right: 10 },
            styles: {
                fontSize: 10,
                halign: 'left',
                cellPadding: 3,
                lineColor: [128, 128, 128],
                lineWidth: 0.1,
            },
            showHead: 'never',
            columnStyles: {
                0: { cellWidth: 35 }, // First column width
                1: { halign: 'left' } // Align second column text to the left
            }
        });

        yOffset = doc.lastAutoTable.finalY + 15;

        // Quotation Heading
        doc.setFontSize(16);
        doc.setFont('helvetica', 'bold');
        if (yOffset + 20 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }
        doc.text(`${formData.InvoiceOrQuotation}`, pageWidth / 2, yOffset, null, null, "center");
        yOffset += 15;

        let rows = formData.items.map((item, index) => [
            index + 1,
            ...Object.values(item),
        ]);

        let headers = [
            'Serial No.',
            ...Object.keys(formData.items[0]).map((item) =>
                item === 'area'
                    ? 'Area/item'
                    : item.charAt(0).toUpperCase() + item.slice(1)
            )
        ];

        const columnCount = headers.length;

        const lastColumnIndex = headers.length - 1;
        rows.push([ // Advance payment row
            { content: `Advance payment ${formData.advancePayment}%`, colSpan: columnCount - 1, styles: { halign: 'center' } },
            `Rs:${formData.totalAmount * (formData.advancePayment / 100)}`
        ]);

        rows.push([ // Total row
            { content: 'All total', colSpan: columnCount - 1, styles: { halign: 'center' } },
            `Rs:${formData.totalAmount}`
        ]);

        if (yOffset + 20 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }

        doc.autoTable({
            head: [headers],
            body: rows,
            startY: yOffset,
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: [128, 128, 128],
                lineWidth: 0.1,
                halign: 'center',
            },
            headStyles: {
                fillColor: InvoiceType ? [100, 163, 79] : [196, 178, 5],
                textColor: [255, 255, 255],
                fontStyle: 'bold',
                halign: 'center',
            },
            columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 70, halign: 'left' }
            },
            tableWidth: pageWidth - 20,
            margin: { top: 10, left: 10, right: 10 },
            didParseCell: function (data) {
                if (data.cell.text[0].includes("Advance payment") || data.cell.text[0] === "All total") {
                    data.cell.styles.fontStyle = 'bold';
                }
            }
        });

        yOffset = doc.lastAutoTable.finalY + 10;

        // Amount in Words
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        if (yOffset + 10 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }
        const amountInWordsText = `Amount in Words: ${formData.amountInWords}`;
        const textWidth = doc.getTextWidth(amountInWordsText);
        const xPosi = (pageWidth - textWidth) / 2;

        doc.text(amountInWordsText, xPosi, yOffset);

        yOffset += 15;
        if (yOffset + 70 > pageHeight) { // Check if content fits on the current page
            doc.addPage();
            addHeader(); // Add the header on the new page
            yOffset = margin + 35; // Reset yOffset for new page
        }
        // Notes
        doc.text(InvoiceType ? "Notes:" : "Terms and Conditions:", margin, yOffset);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.text(formatText(formData.notes), margin, yOffset + 10, { maxWidth: pageWidth - 2 * margin });

        yOffset += 30; // Move yOffset down for the footer

        // Draw Yellow Bar at the Bottom
        const barHeight = 10;
        const yPosition = pageHeight - barHeight;
        doc.setFillColor(...(InvoiceType ? [100, 163, 79] : [196, 178, 5]));
        doc.rect(0, yPosition, pageWidth, barHeight, 'F');

        // Footer Text
        doc.setFontSize(10);
        doc.setTextColor(255, 255, 255);
        doc.text("Thank you for your business!", pageWidth / 2, yPosition + 6, { align: 'center' });

        // Save the document
        doc.save(`Invoice_${formData.clientName}.pdf`);
    };
    const handleColumnChange = (index, newKey) => {
        setFormData(prevData => {
            const updatedItems = prevData.items.map(item => {
                // Clone the item to avoid mutating the existing object
                const updatedItem = { ...item };

                // Get the current key by indexing into Object.keys(item)
                const currentKey = Object.keys(updatedItem)[index];

                if (currentKey !== undefined) {
                    const value = updatedItem[currentKey];

                    // Remove the old key
                    const { [currentKey]: _, ...rest } = updatedItem;

                    // If newKey is empty, allow it to be set to an empty string temporarily
                    const newKeyToSet = newKey;

                    // Insert the new key (even if it's an empty string)
                    const entries = Object.entries(rest);
                    entries.splice(index, 0, [newKeyToSet, value]);

                    // Convert entries back to an object
                    const newItem = Object.fromEntries(entries);

                    return newItem;
                }

                return updatedItem;
            });

            return {
                ...prevData,
                items: updatedItems,
            };
        });
    };

    const generateUniqueKey = (existingKeys) => {
        let newKey;
        let counter = 1;
        do {
            newKey = `New Column ${counter}`;
            counter++;
        } while (existingKeys.includes(newKey));
        return newKey;
    };

    const addColumnNextToIndex = (index) => {
        // Generate a unique key for the new column
        const existingKeys = Object.keys(formData.items[0]);
        const newKey = generateUniqueKey(existingKeys);

        // Add new key-value pair to each item
        const updatedItems = formData.items.map(item => {
            const entries = Object.entries(item);
            entries.splice(index + 1, 0, [newKey, '']); // Add new key-value pair
            return Object.fromEntries(entries);
        });

        setFormData(prev => ({ ...prev, items: updatedItems }));
    };

    const removeColumn = (index) => {
        const updatedItems = formData.items.map(item => {
            const keys = Object.keys(item);
            if (index < keys.length) {
                const newItem = { ...item };
                delete newItem[keys[index]]; // Remove the key at the index
                return newItem;
            }
            return item;
        });

        setFormData(prev => ({ ...prev, items: updatedItems }));
    };




    return (
        <div className={classes.container}>

            <div className={classes.header}>
                <img src={InvoiceType === 'Easel' ? "easel.png" : "InvoiceLogo.png"} alt="Logo" className={classes.logo} />
                <div className={classes.constants}>
                    {InvoiceType === 'Easel'
                        ? InvoiceConstants.HeaderIcons2.phone.text.split('\n').map((item) => <p>Phone: {item}</p>)
                        : InvoiceConstants.HeaderIcons.phone.text.split('\n').map((item) => <p>Phone: {item}</p>)
                    }

                    {InvoiceType === 'Easel' ?
                        InvoiceConstants.HeaderIcons2.location.text.split('\n').map((item) => <p>Location: {item}</p>) :
                        InvoiceConstants.HeaderIcons.location.text.split('\n').map((item) => <p>Location: {item}</p>)}
                    {InvoiceType === 'Easel' ?
                        InvoiceConstants.HeaderIcons2.globe.text.split('\n').map((item) => <p>Website:  {item}</p>) :
                        InvoiceConstants.HeaderIcons.globe.text.split('\n').map((item) => <p>Website:  {item}</p>)}
                </div>
            </div>
            <div className={classes.subContainer}>
                <div className={classes.header}>
                    <div className={classes.BelowHeader}>
                        <div>
                            <p>Reference: {InvoiceType ? InvoiceConstants.BelowHeader2.Reference : InvoiceConstants.BelowHeader.Reference}</p>

                            <CssTextField
                                label="Date"
                                type="date"
                                value={formData.date}
                                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                            />
                        </div>
                        <div>
                            {!InvoiceType &&
                                <>
                                    <p>GST#: {InvoiceConstants.BelowHeader.GST}</p>
                                    <p>NTN#: {InvoiceConstants.BelowHeader.NT}
                                    </p>
                                </>}
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                        {InvoiceConstants.firstOfAll}
                    </p>
                </div>
                <div style={{ overflowX: 'auto' }}>
                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Client Name</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <CssTextField
                                        value={formData.clientName}
                                        onChange={(e) => setFormData({ ...formData, clientName: e.target.value })}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Client City</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <CssTextField
                                        value={formData.clientCity}
                                        onChange={(e) => setFormData({ ...formData, clientCity: e.target.value })}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.tableCell}>Client Phone</TableCell>
                                <TableCell className={classes.tableCell}>
                                    <CssTextField
                                        value={formData.clientPhone}
                                        onChange={(e) => setFormData({ ...formData, clientPhone: e.target.value })}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                <div style={{ textAlign: 'center', marginTop: '5%', marginBottom: '5%' }}>
                    <CssTextField
                        label='Doc Type: (e.g Quoration or Invoice)'
                        value={formData.InvoiceOrQuotation} // Display the current key
                        onChange={(e) => setFormData({ ...formData, InvoiceOrQuotation: e.target.value })} // Pass the new key
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />

                </div>

                <div className={classes.totalContainer}>
                    <div style={{ overflowX: 'auto' }}>
                        <Table className={classes.table}>
                            <TableHead style={{ borderBottom: InvoiceType ? '2px solid #64a34f' : '2px solid #c4b205', color: 'white' }}>
                                <TableRowComponent>
                                    {Object.keys(formData.items[0]).map((column, index) => (
                                        <TableCell key={index} className={classes.tableCell}>
                                            <CssTextFieldHeading
                                                value={column} // Display the current key
                                                onChange={(e) => handleColumnChange(index, e.target.value)} // Pass the new key
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            />
                                            <div style={{ textAlign: 'center' }}>
                                                <IconButton className={InvoiceType ? classes.button2 : classes.button} onClick={() => addColumnNextToIndex(index)}>
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton className={classes.buttonDel} onClick={() => removeColumn(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ position: 'relative', }}>
                                        <div style={{ position: 'absolute', bottom: 10, color: 'white' }}>
                                            Actions
                                        </div>
                                    </TableCell>
                                </TableRowComponent>
                            </TableHead>
                            <TableBody>
                                {formData.items.map((item, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {Object.keys(item).map((key, colIndex) => (
                                            <TableCell key={colIndex} className={classes.tableCell}>
                                                <CssTextField
                                                    name={key}
                                                    multiline
                                                    type={(key === 'rate' || key === 'area') ? 'number' : 'text'}
                                                    value={item[key]} // Bind value from the item object
                                                    onChange={(e) => handleInputChange(rowIndex, key, e.target.value)} // Pass key and value
                                                    fullWidth
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell style={{ display: 'flex', flexDirection: 'row' }}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveRow(rowIndex)}
                                                className={classes.buttonDel}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                            <IconButton
                                                className={InvoiceType ? classes.button2 : classes.button}
                                                onClick={handleAddRow}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>


                    <CssTextField
                        label="Advance Payment Percentage"
                        type="number"
                        value={formData.advancePayment}
                        onChange={(e) => setFormData({ ...formData, advancePayment: parseFloat(e.target.value) })}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                    />
                    <div className={classes.amountContainer}>
                        <p>Total Amount: {formData.totalAmount}</p>
                        <p>Advance Payment: {formData.totalAmount * (formData.advancePayment / 100)}</p>
                        <p>Amount in Words: {formData.amountInWords}</p>
                    </div>
                </div>
            </div>
            <div className={classes.notesContainer}>
                <CssTextField
                    label={InvoiceType ? "Terms and conditions" : "Notes"}
                    value={formData.notes}
                    onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                />
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button
                    variant="contained"
                    onClick={generatePDF}
                    className={InvoiceType ? classes.generateButton2 : classes.generateButton}
                >
                    Generate PDF
                </Button>
            </div>
        </div>
    );
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: 'black'
        },
        '& label.Mui-focused': {
            color: '#b5b519',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#b5b519',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#b5b519',
            },
            '&:hover fieldset': {
                borderColor: '#d9d91c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#b5b519',
            },
        },
        '& .MuiInputBase-input': {
            color: 'black',  // Ensure the text color is black
        },
    },
})(TextField);

const CssTextFieldHeading = withStyles({
    root: {
        '& label': {
            color: 'white'
        },
        '& label.Mui-focused': {
            color: '#b5b519',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#b5b519',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#d9d91c',
            },
            '&:hover fieldset': {
                borderColor: '#d9d91c',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#d9d91c',
            },
        },
        '& .MuiInputBase-input': {
            color: 'white',  // Ensure the text color is black
        },
    },
})(TextField);


const useStyles = makeStyles((theme) => ({
    container: {
        margin: "0 auto",
        maxWidth: "800px",  // Define a fixed max width for the container
        padding: theme.spacing(2),
        paddingTop: 0,
        background: 'white',
    },
    subContainer: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    header: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column'   // Applied when screen width is 'sm' or below
        },
        marginBottom: theme.spacing(2),
    },
    logo: {
        maxHeight: "180px",
        [theme.breakpoints.down('sm')]: {
            maxHeight: '100px',   // Applied when screen width is 'sm' or below
        },
        width: '200px'
    },
    constants: {
        textAlign: "right",
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',   // Applied when screen width is 'sm' or below
        },
        fontSize: "12px",
        paddingTop: theme.spacing(2),
    },
    BelowHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column'   // Applied when screen width is 'sm' or below
        },
        width: '100%',
        marginBottom: '5%'
    },
    fields: {
        marginBottom: theme.spacing(2),
    },
    table: {
        marginBottom: theme.spacing(2),
        borderCollapse: "collapse",
        width: "100%",
        minWidth: 650
    },
    tableCell: {
        border: "1px solid #ddd",
        padding: theme.spacing(1),
    },
    tableHeadCell: {
        border: "1px solid #ddd",
        padding: theme.spacing(1),
        backgroundColor: "#c4b205",
        fontWeight: "bold",
    },
    button: {
        padding: 0,
        backgroundColor: 'white',
        color: '#c4b205',
        '&:hover': {
            color: 'white',
            backgroundColor: '#d1be04',
        },
    },
    button2: {
        padding: 0,
        backgroundColor: 'white',
        color: '#366932',
        '&:hover': {
            color: 'white',
            backgroundColor: '#d1be04',
        },
    },

    buttonDel: {
        padding: 0,
        color: '#f41c6c',
        '&:hover': {
            backgroundColor: 'rgba(244, 28, 108,0.2)',
        },
    },
    totalContainer: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc',
        borderRadius: '4px',
    },
    amountContainer: {
        marginTop: theme.spacing(1),
    },
    notesContainer: {
        marginTop: theme.spacing(2),
    },
    generateButton: {
        marginTop: theme.spacing(5),
        backgroundColor: '#c4b205',
        color: 'white',
        '&:hover': {
            color: 'white',
            backgroundColor: '#d1be04',

        },
    }
    ,
    generateButton2: {
        marginTop: theme.spacing(5),
        backgroundColor: '#366932',
        color: 'white',
        '&:hover': {
            color: 'white',
            backgroundColor: '#64a34f',

        },
    }
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#c4b205',
        },
    },
}))(TableRow);

const StyledTableRow2 = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#64a34f',
        },
    },
}))(TableRow);
export default InvoiceMaker;
