import React from "react";
import {Grid, Typography, Button, Box} from '@material-ui/core'
import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
const Links = () => {
    const mdDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
    
    const history = useHistory();
    const classes = useStyles()
    const scrollTop=()=>{
        window.scrollTo(0, 0);
    }
    const handlehome =()=>{
        history.push('/')
        scrollTop()
    }
    const handleabout =()=>{
        history.push('/about');
        scrollTop()
    }
    const handleContact =()=>{
        history.push('/contactus');
        scrollTop()
    }
    
    const handleProjects =()=>{
        history.push('/projects');
        scrollTop()
    }
    return(
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Typography variant='h5' className={classes.title}  style={{textAlign:"center"}}>Site Links</Typography>
                <Box className={classes.vertical}>
                <Button className={classes.link} onClick={handlehome}>Home</Button>
                <Button className={classes.link} onClick={handleProjects}>Projects</Button>
                <Button className={classes.link} onClick={handleabout}>About</Button>
                <Button className={classes.link} onClick={handleContact}>Contact</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container:{
        paddingLeft: 5,
        paddingRight: 10,
    },
    vertical:{
        display: 'flex',
        flexDirection: 'column',
        textAlign:"center",
        marginTop:"3vh"
    },
    link:{
        marginTop: 5,
        color: theme.palette.secondaryText,
    },
    title: {
        color: 'white',
        fontWeight: 'bold',
    }
}))

export default Links;
