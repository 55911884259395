import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxComponent = ({ label, checked, onChange, checkarray }) => {
  const options = ['Crousel 1', 'Crousel 2', 'Crousel 3'];
  const [isCheckedArray, setIsCheckedArray] = useState(
    options.map(() => checked || false)
  );

  const handleChange = (event, index) => {
    const newIsCheckedArray = isCheckedArray.map((value, idx) =>
      idx === index ? event.target.checked : false
    );

    setIsCheckedArray(newIsCheckedArray);

    if (onChange) {
      onChange(event.target.checked, index);
    }

    checkarray(newIsCheckedArray);
  };

  return (
    <>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              style={{ color: 'white' }}
              checked={isCheckedArray[index]}
              onChange={(event) => handleChange(event, index)}
              disabled={isCheckedArray[index] && isCheckedArray.indexOf(true) !== index}
            />
          }
          label={option}
        />
      ))}
    </>
  );
};

export default CheckboxComponent;
