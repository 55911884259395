import React from 'react';
import { DashboardData } from './dashboardConstants.js';
import { Grid, Card, Typography, IconButton } from '@material-ui/core';
import { DashboardOutlined } from '@material-ui/icons';
import welcomeImg from '../../img/welcome.png';
import './dashboard.css';
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FloatingActionButtonZoom from './EditAdd/AddEdit.js'
import ScrollComponent from './Hrscrolls/horizontalScrolls.js'
import ProjectInfo from './Projectinformations/projectinfo.js';


const Dashboard = () => {
  const currentDate = new Date().toLocaleDateString();
  const mdDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const direction = mdDown ? 'column' : 'row';
  return (
    <div style={{ background: 'transparent' }}>
      <Grid container style={{ marginLeft: 'auto', }}>
        <Grid item xs={6} md={6}>
          <div style={{ fontSize: '20px', paddingRight: '2px', color: "#c4b205", display: "flex", flexDirection: "row" }}>
            <span >
              Dashboard
            </span>
            <span style={{ paddingLeft: '5px' }}>
              <div style={{ display: "flex", alignItems: "center" }}><DashboardOutlined /></div>
            </span>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div style={{ textAlign: "right", color: '#c4b205' }}>
            <span style={{ paddingRight: '5px' }}>
            </span>
            {currentDate}
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginLeft: 'auto' }}>
        <Grid item xs={12} md={9}>
          {/* Card content */}
          <Card variant="outlined">
            <div style={{ display: 'flex', color: 'white', marginTop: "5%" }}>
              <div >
                <Typography variant='h5' style={{ marginBottom: "2%" }}>{DashboardData.title}</Typography>
                <div style={{ textAlign: "justify" }}>{DashboardData.content}</div>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={welcomeImg} alt="." className='welcomeimg' />
          </div>
        </Grid>
      </Grid>

      <Divider style={{ color: "white", background: "white", margin: "20px" }} />
      <Typography variant='h5' style={{ color: "white" }}>Crousel Items</Typography>
      <b style={{ color: "white", textAlign: "justify" }}>Select any one of three images to alter and display on the home screen</b>

      <Grid container style={{ marginTop: "15px" }}>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FloatingActionButtonZoom />
          </div>
        </Grid>
      </Grid>
      <Divider style={{ color: "white", background: "white", margin: "20px" }} />
      <Typography variant='h5' style={{ color: "white" }}>Scrolls on Homepage</Typography>
      <b style={{ color: "white", textAlign: "justify" }}>Select upto three types which should be displayed on home screen </b>
      <Grid container style={{ marginTop: "15px" }}>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex", justifyContent: "center", }}>
            <div style={{ width: "70%", textAlign: "left" }}>
              <ScrollComponent />
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider style={{ color: "white", background: "white", margin: "20px" }} />
      <Typography variant='h5' style={{ color: "white" }}>Project screen informations</Typography>
      <b style={{ color: "white", textAlign: "justify" }}>Add these 3 fields and save it you will see the changes in the project screen in home apge</b>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
        <ProjectInfo />
      </div>


    </div>
  );
};

export default Dashboard;

